import React, {useEffect, useState} from 'react';
import Notification from '@rio-cloud/rio-uikit/Notification';


export const FileDownloader = (props: {
    className?: string;
    filename: string;
    downloadMethod: (downloadProps: { filename: string; setErrorMessage: (message: string) => void }) => void;
}) => {

    const [fetchFile, setFetchFile] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (errorMessage !== undefined && errorMessage !== '') {
            Notification.error(errorMessage);
            setErrorMessage('');
        }
    }, [errorMessage]);

    useEffect(() => {
        if (fetchFile) {
            props.downloadMethod({setErrorMessage, ...props});
            setFetchFile(false);
        }
    }, [fetchFile]);

    const handleOnClick = () => {
        setFetchFile(true);
    };

    const baseClassName = 'fileDownloadIcon rioglyph rioglyph-download padding-left-5';

    const className = props.className ? `${props.className} ${baseClassName}` : baseClassName;

    return (
        <span key="sreFilename"
              className={className}
              onClick={handleOnClick}/>
    );
};