import {accessToken} from '../configuration/tokenHandling/accessToken';
import {cloneDeep} from 'lodash';
import merge from 'lodash/fp/merge';

export class RequestServices {
    private static deserializeBody(bodyString: string) {
        return bodyString === '' ? {} : JSON.parse(bodyString);
    }

    static async post(data: any, url: string) {
        return RequestServices.authorizedRequest(
            url,
            {
                method: 'POST',
                body: JSON.stringify(data),
                mode: 'cors'
            }
        ).then((resp: Response) => resp.text().then((bodyString: any) => {
                const body = this.deserializeBody(bodyString);
                return Promise.resolve({
                    status: resp.status,
                    message: resp.statusText,
                    body
                });
            }
        ));
    }

    static async get<T>(url: string): Promise<HttpResponse<T>> {
        const response: Response = await RequestServices.authorizedRequest(
            url, {
                method: 'GET',
                mode: 'cors'
            });
        const bodyString: string = await response.text();
        const body = this.deserializeBody(bodyString);
        return {
            status: response.status,
            data: body
        };
    }

    static async delete(url: string): Promise<Response> {
        return RequestServices.authorizedRequest(url, {method: 'DELETE'});
    }

    private static authorizedRequest(url: string, options: HttpOptions, fetch = window.fetch): Promise<Response> {
        return fetch(
            url,
            merge(cloneDeep(options), {
                headers: {
                    Authorization: `Bearer ${accessToken.getAccessToken()}`,
                    'Content-type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    // 'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE,HEAD,OPTIONS',
                    // 'Access-Control-Allow-Headers':
                    //     'Content-Type, Origin, Accept, Authorization, Content-Length, X-Requested-With',
                }
            })
        );
    }
}

interface HttpOptions {
    method: 'POST' | 'GET' | 'DELETE';
    body?: any;
    mode?: 'cors';
}

export interface HttpResponse<T> {
    status: number;
    data: T;
}
