import React from 'react';
import {deviceColumnDescriptors} from '../../device-sidebar/data-descriptors/deviceDetailsColumnDescriptors';
import {ColumnDescriptorProps, ListTable} from '../../../../../components/table/ListTable';
import {useDispatch} from 'react-redux';
import {DeviceStateCommon} from '../../device-sidebar/device-state.types';
import {saveDeviceState, setSelectedDeviceSidebar} from '../../device-sidebar/sidebarSlice';
import {TAB_NAME} from '../../device-sidebar/SidebarTabs';

interface DeviceListTableProps {
    devices: DeviceStateCommon[];
    columnOrder: string[];
    hiddenColumns: string[];
    isLoading: boolean;
}

export const findDeviceByOriginator =
    (devices: DeviceStateCommon[], originator: string): DeviceStateCommon | undefined => {
        return devices.find((value) => value.originator === originator);
    };

export const DeviceListTable = ({devices, columnOrder, hiddenColumns, isLoading}: DeviceListTableProps) => {
    const dispatch = useDispatch();

    const columns = deviceColumnDescriptors;
    const filteredAndOrderedColumns: ColumnDescriptorProps<DeviceStateCommon>[] = [];
    columnOrder.forEach(columnId => {
        if (!hiddenColumns.includes(columnId)) {
            const selectedColumn: ColumnDescriptorProps<DeviceStateCommon> | undefined =
                columns.find((column) => column.id === columnId);
            delete selectedColumn?.hidden;
            if (selectedColumn) {
                filteredAndOrderedColumns.push(selectedColumn);
            }
        }
    });

    const toggleRow = (originator: string, isActiveRowId: boolean) => {
        const device = findDeviceByOriginator(devices, originator);
        if (isActiveRowId && device) {
            dispatch(saveDeviceState(device));
            dispatch(setSelectedDeviceSidebar({
                device: {
                    serialNumber: device.serialNumber,
                    type: device.type,
                    originator: device.originator
                },
                context: TAB_NAME.DETAILS
            }));
        } else {
            dispatch(setSelectedDeviceSidebar(undefined));
        }
    };


    return (
        <ListTable className="table-bordered table-condensed height-100pct"
                   items={devices}
                   highlightSelectedRow
                   itemKey="originator"
                   onRowClick={(rowId, isActiveRowId) => toggleRow(rowId, isActiveRowId)}
                   columnDescriptors={filteredAndOrderedColumns}
                   isLoading={isLoading}
                   showHeader
        />
    );
};
