import { ReactNode } from 'react';

export interface AppHeaderProps {
    selectedMenu: EHeaderMenuKeys;
}

export interface NavItem {
    key: string;
    route: ReactNode;
}

export interface AppHeaderState {
    navItems: NavItem[];
}

export enum EHeaderMenuKeys {
    NONE = '',
    MBB_CONNECTOR = 'mbb-admin-web',
    DEVICE_STATE = 'device-state-web',
    VEHICLE_HEATMAP = 'vehicle-heatmap',
    BACKEND_DEVICE = 'backend-device'
}