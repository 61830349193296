import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {ListTable} from '../../../../../components/table/ListTable';
import {nasysColumnDescriptors} from './columnDescriptors';
import {useFetchJournalNasysStatesQuery} from '../../../../../services/journalNasysStateApi';
import {DefaultWhiteColumn} from '../../../../../components/DefaultWhiteColumn';

export const NasysJournal = () => {
    const [page, setPage] = useState(0);
    const {data, isLoading, refetch} = useFetchJournalNasysStatesQuery({page});

    useEffect(() => {
        refetch();
    }, []);

    let content = [];
    let isLastPage = true;
    if (data) {
        content = data.content;
        isLastPage = data.last;
    }

    return (
        <div>
            <ListTable itemKey={'id'}
                       items={content}
                       isLoading={isLoading}
                       className={'table, table-bordered'}
                       columnDescriptors={nasysColumnDescriptors}
                       showHeader
            />
            {!isLastPage &&
              <DefaultWhiteColumn key={'loadMore'} className="text-center padding-bottom-20">
                <a id="load-more-button"
                   className="btn btn-default"
                   onClick={() => setPage(page + 1)}>
                  <FormattedMessage id="intl-msg:coreconAdminWeb.loadMore"/>
                </a>
              </DefaultWhiteColumn>
            }
        </div>
    );
};
