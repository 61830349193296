import {prepareHeaders} from './journalStoneridgeStateApi';

export const downloadFileFromAPICall = (props: {
    url: string;
    filename: string;
    setErrorMessage: (message: string) => void;
}) => {
    fetch(props.url, {
        method: 'GET',
        headers: prepareHeaders(new Headers())
    }).then(
        response => {
            if (response.ok) {
                return response.blob();
            } else {
                return Promise.reject();
            }
        })
        .then(
            (blob) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = props.filename;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(props.url);
            },
            error => {
                props.setErrorMessage(`Failed to fetch ${props.filename}`);
                console.error(error);
            });
};