import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {DefaultWhiteColumn} from '../../../../../components/DefaultWhiteColumn';
import {DeviceListTable} from './DeviceListTable';
import {SIGNING_STATE, SigningFilter} from '../signing.types';
import {DEVICE_TYPE} from '../../device-sidebar/device-state.types';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import {SigningFilterInput} from './SigningFilterInput';
import {Moment} from 'moment/moment';
import {
    useLazyFetchDeviceSigningStatesCM4Query,
    useLazyFetchDeviceSigningStatesQuery
} from '../../../../../services/deviceSigningStateApi';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

export interface DeviceListProps {
    hide: boolean;
    signingFilter: SigningFilter;
    onUpdateSelectedSigningState: (signingState: SIGNING_STATE) => void;
    onUpdateSelectedDeviceType: (deviceType: DEVICE_TYPE) => void;
    onTimeRangeChange: (startValue: Moment, endValue: Moment) => void;
}

const RESULT_SIZE = 100;
export const DeviceList = (deviceListProps: DeviceListProps) => {
        const {formatMessage} = useIntl();
        const {
            signingFilter,
            onUpdateSelectedSigningState,
            onUpdateSelectedDeviceType,
            onTimeRangeChange,
        } = deviceListProps;
        const [page, setPage] = useState(0);
        const [resultSize, setResultSize] = useState(RESULT_SIZE);

        const [fetchDeviceSigningStates, {
            data: dataTBM3,
            error: errorTBM3,
            isFetching: isFetchingTBM3
        }] = useLazyFetchDeviceSigningStatesQuery();
        const [fetchDeviceSigningCM4States, {
            data: dataCM4,
            error: errorCM4,
            isFetching: isFetchingCM4
        }] = useLazyFetchDeviceSigningStatesCM4Query();
        const data = signingFilter.deviceType === DEVICE_TYPE.CM4 ? dataCM4 : dataTBM3;
        const error = signingFilter.deviceType === DEVICE_TYPE.CM4 ? errorCM4 : errorTBM3;
        const isFetching = isFetchingTBM3 || isFetchingCM4;

        useEffect(() => {
            if (signingFilter.deviceType === DEVICE_TYPE.CM4) {
                fetchDeviceSigningCM4States(
                    {
                        signingFilter: {
                            deviceType: signingFilter.deviceType,
                            signingState: signingFilter.signingState,
                            startDateTime: signingFilter.startDateTime,
                            endDateTime: signingFilter.endDateTime,
                        },
                        signingPage: {page, size: resultSize}
                    },
                );
            } else {
                fetchDeviceSigningStates(
                    {
                        signingFilter: {
                            deviceType: signingFilter.deviceType,
                            signingState: signingFilter.signingState,
                            startDateTime: signingFilter.startDateTime,
                            endDateTime: signingFilter.endDateTime,
                        },
                        signingPage: {page, size: resultSize}
                    },
                );
            }
        }, [signingFilter, page, resultSize]);

        // @ts-ignore
        const hasMore = data?.totalElements > data?.numberOfElements;

        const handleLoadMore = () => {
            setResultSize(resultSize + RESULT_SIZE);
        };

        if (deviceListProps.hide) {
            return <></>;
        }

        return (
            <>
                <DefaultWhiteColumn className="padding-1">
                    <TableToolbar>
                        <div className="table-toolbar-container">
                            <div className="table-toolbar-group-left">
                                <SigningFilterInput
                                    onUpdateSelectedSigningState={(signingState) =>
                                        onUpdateSelectedSigningState(signingState)
                                    }
                                    onUpdateSelectedDeviceType={(deviceType) =>
                                        onUpdateSelectedDeviceType(deviceType)
                                    }
                                    onTimeRangeChange={
                                        (startValue: Moment, endValue: Moment) =>
                                            onTimeRangeChange(startValue, endValue)
                                    }
                                    signingFilter={signingFilter}
                                />
                            </div>
                        </div>
                    </TableToolbar>
                </DefaultWhiteColumn>
                <DefaultWhiteColumn className="padding-1">
                    <h5 key="header" className="margin-left-5">
                        {
                            isFetching ? <ContentLoader/> :
                                `${formatMessage(
                                    {
                                        id: 'intl-msg:coreconAdminWeb.deviceSigning.totalDevices'
                                    }
                                    // @ts-ignore
                                )} ${data?.length || 0}`
                        }
                    </h5>
                </DefaultWhiteColumn>
                <DefaultWhiteColumn className="padding-top-1 padding-bottom-20 user-select-none
            overflow-auto max-height-1000">
                    <DeviceListTable
                        // @ts-ignore
                        devices={data && !error ? data : []}
                        type={signingFilter.deviceType}
                        isLoading={isFetching}
                    />
                </DefaultWhiteColumn>
                {(hasMore) &&
                    <DefaultWhiteColumn key={'loadMore'} className="text-center padding-bottom-20">
                        <a id="load-more-button"
                           className="btn btn-default"
                           onClick={handleLoadMore}>
                            <FormattedMessage id="intl-msg:coreconAdminWeb.loadMore"/>
                        </a>
                    </DefaultWhiteColumn>
                }
            </>
        );
    }
;
