import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import DataTab from '@rio-cloud/rio-uikit/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/DataTabs';
import {NasysJournal} from './journal/NasysJournal';
import {StoneridgeJournal} from './journal/StoneridgeJournal';
import {ContinentalJournal} from './journal/ContinentalJournal';
import Upload from './upload/Upload';

const STONERIDGE_JOURNAL_TAB = 'stoneridge_tab';
const NASYS_JOURNAL_TAB = 'nasys_tab';
const CONTINENTAL_JOURNAL_TAB = 'continental_tab';

const UploadHome = () => {
    const [tabKey, setTabKey] = useState(STONERIDGE_JOURNAL_TAB);

    return (
        <div className="container-fluid">
            <div className="row margin-bottom-20">
                <div className="col-md-12">
                    <h1><FormattedMessage id="intl-msg:coreconAdminWeb.upload.moduleName"/></h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Upload/>
                </div>
            </div>
            <br/>
            <br/>
            <div className="row">
                <div className="col-md-12">
                    <h5><FormattedMessage id="intl-msg:coreconAdminWeb.upload.uploadResults"/></h5>
                </div>
            </div>
            <DataTabs activeKey={tabKey} onSelectTab={(newTabKey: string) => setTabKey(newTabKey)}>
                <DataTab
                    tabKey={STONERIDGE_JOURNAL_TAB}
                    title={'Stoneridge'}>
                    <StoneridgeJournal/>
                </DataTab>
                <DataTab
                    tabKey={NASYS_JOURNAL_TAB}
                    title={'Nasys'}>
                    <NasysJournal/>
                </DataTab>
                <DataTab
                    tabKey={CONTINENTAL_JOURNAL_TAB}
                    title={'Continental'}>
                    <ContinentalJournal/>
                </DataTab>
            </DataTabs>
        </div>
    );
};

export default UploadHome;
