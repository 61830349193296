import React from 'react';
import {deviceColumnDescriptors} from './deviceColumnDescriptors';
import {ListTable} from '../../../../../components/table/ListTable';
import {useDispatch} from 'react-redux';
import {ApiDeviceSigningDataModelTBM3} from '../../device-sidebar/device-signing.types';
import {DEVICE_TYPE} from '../../device-sidebar/device-state.types';
import {setSelectedDeviceSidebar} from '../../device-sidebar/sidebarSlice';
import {TAB_NAME} from '../../device-sidebar/SidebarTabs';

interface DeviceListTableProps {
    devices: ApiDeviceSigningDataModelTBM3[];
    isLoading: boolean;
    type: DEVICE_TYPE;
}

export const DeviceListTable = ({devices, isLoading}: DeviceListTableProps) => {
    const dispatch = useDispatch();

    const toggleRow = (rowId: string, isActiveRowId: boolean) => {
        const device = devices.find(value => value.serialNumber === rowId);
        if (isActiveRowId && device) {
            dispatch(setSelectedDeviceSidebar({
                device: {
                    serialNumber: device.serialNumber,
                    type: device.deviceType,
                    originator: ''
                },
                context: TAB_NAME.SIGNING_DETAILS
            }));
        } else {
            dispatch(setSelectedDeviceSidebar(undefined));
        }
    };

    return (
        <ListTable className="table-bordered table-condensed height-100pct"
                   items={devices}
                   highlightSelectedRow
                   itemKey="serialNumber"
                   onRowClick={(rowId, isActiveRowId) => toggleRow(rowId, isActiveRowId)}
                   columnDescriptors={deviceColumnDescriptors}
                   isLoading={isLoading}
                   showHeader
        />
    );
};
