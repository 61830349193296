import {RequestServices} from '../../../../../../services/requestServices';
import {DELETE_RIO_MFH_DEVICE_URL, MVS_MODE_URL, TSS_KAFKA_URL, UNREGISTER_VIN_URL,} from './VehicleDataEndpoints';
import Notification from '@rio-cloud/rio-uikit/Notification';
import {EMvsGeoStatus, ETssSubscriptionStatus, SubscriptionChangeRequest} from './VehicleData.types';

export class VehicleDataServices {

    static async unregisterVin(vin: string) {
        await RequestServices.delete(UNREGISTER_VIN_URL.replace('{vin}', vin));
    }

    static async unregisterFromMFH(vin: string) {
        await RequestServices.delete(DELETE_RIO_MFH_DEVICE_URL.replace('{vin}', vin));
    }

    static async tssKafkaSubscriptionChange(vin: string,
                                            newStatus: ETssSubscriptionStatus,
                                            oldStatus: ETssSubscriptionStatus) {
        const request = {
            vin,
            newStatus,
            oldStatus
        };
        await VehicleDataServices.subscriptionChangeRequest(
            request, TSS_KAFKA_URL, 'Successfully requested a tss subscription change');
    }

    static async mvsGeoSubscriptionChange(vin: string, newStatus: EMvsGeoStatus, oldStatus: EMvsGeoStatus) {
        const request = {
            vin,
            newStatus,
            oldStatus
        };
        await VehicleDataServices.subscriptionChangeRequest(
            request, MVS_MODE_URL, 'Successfully requested a mvs geo change');
    }

    private static async subscriptionChangeRequest<T>(request: SubscriptionChangeRequest<T>,
                                                      url: string,
                                                      successMessage: string) {
        try {
            const response = await RequestServices.post(request, url);
            if (response.status === 200) {
                Notification.success(successMessage);
            } else if (response.status === 400) {
                if (response.body?.detail) {
                    Notification.warning(`Request failed because: ${response.body?.detail}`);
                } else {
                    Notification.warning(`Request failed with status: ${response.status}`);
                }
            } else if (response.status === 502) {
                Notification.warning('Request could not be performed due to MVSGeo communication error');
            } else {
                Notification.warning(`Request failed with status: ${response.status}`);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
