import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import classname from 'classnames';

export const colorSchemeCookieName = 'uikit-color-scheme';

export const ThemeToggler = () => {
    const [cookies, setCookie, removeCookie] = useCookies([colorSchemeCookieName]);
    const [isDarkMode, setDarkMode] = useState(cookies[colorSchemeCookieName] === 'dark');
    const deviceInDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

    useEffect(() => {
        if (cookies[colorSchemeCookieName] !== undefined && cookies[colorSchemeCookieName] !== 'system') {
            return;
        }

        if (deviceInDarkMode.matches) {
            document.documentElement.setAttribute('data-theme', 'dark');
            setDarkMode(true);
        } else {
            document.documentElement.removeAttribute('data-theme');
            setDarkMode(false);
        }
    }, [deviceInDarkMode]);

    const handleDarkModeButton = (mode: string) => {
        if (mode === 'dark') {
            setDarkMode(true);
            setCookie(colorSchemeCookieName, 'dark');
            document.documentElement.setAttribute('data-theme', 'dark');
        } else if (mode === 'light') {
            setDarkMode(false);
            setCookie(colorSchemeCookieName, 'default');
            document.documentElement.removeAttribute('data-theme');
        } else if (mode === 'system') {
            removeCookie(colorSchemeCookieName);
        }
    };

    const listItemClassnames = classname(
        'display-flex padding-x-15 padding-y-10',
        'hover-bg-lightest cursor-pointer',
        'border border-bottom-only border-color-lighter last-child-border-bottom-none'
    );
    const lastListItemClassnames = classname('display-flex padding-x-15 padding-y-10 cursor-pointer rounded-bottom');
    const buttonIconColor = isDarkMode ? 'white' : 'black';
    return (
        <ActionBarItem id='themeToggle'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph rioglyph-color-swatch'/>
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title='Choose a Theme' useOffscreen className='padding-0'>
                <div>
                    <div className={listItemClassnames} onClick={() => handleDarkModeButton('light')}>
                        <div>
                            <span className={'rioglyph rioglyph-weather-sunny'} color={`${buttonIconColor}`}/>
                        </div>
                        <div>
                            <span className='margin-left-5'>Light Mode</span>
                        </div>
                    </div>
                    <div className={listItemClassnames} onClick={() => handleDarkModeButton('dark')}>
                        <div>
                            <span className={'rioglyph rioglyph-mode-dark-light'} color={`${buttonIconColor}`}/>
                        </div>
                        <div>
                            <span className='margin-left-5'>Dark Mode</span>
                        </div>
                    </div>
                    <div className={lastListItemClassnames} onClick={() => handleDarkModeButton('system')}>
                        <div>
                            <span className={'rioglyph rioglyph-desktop'} color={`${buttonIconColor}`}/>
                        </div>
                        <div>
                            <span className='margin-left-5'>System Color</span>
                        </div>
                    </div>
                </div>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};