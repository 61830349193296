import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';

export const copyToClipboard = (text: string, showText: boolean) => {
    // create temporary element
    const el = document.createElement('textarea');
    // assign text
    el.value = text;
    // set element readonly
    el.setAttribute('readonly', '');
    // el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // select text
    el.select();
    // copy selected text
    document.execCommand('copy');
    // remove selected element
    document.body.removeChild(el);

    Notification.success(
        [
            // eslint-disable-next-line react/jsx-key
            <FormattedMessage id="intl-msg:coreconAdminWeb.copiedToClipboard"/>,
            // eslint-disable-next-line react/jsx-key
            <span className="padding-5">{showText && text}</span>
        ]
    );
};

export const truncate = (input: string, amount: number) =>
    input?.length > amount ? `${input.substring(0, amount - 3)}...` : input;

export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}