import {
    ApiDeviceStateMBB,
    ApiDeviceStateMBBList,
    ApiDeviceStateTBM3,
    ApiDeviceStateTBM3List,
    DEVICE_TYPE,
    DeviceStateMBB,
    DeviceStateMBBList,
    DeviceStateTBM3,
    DeviceStateTBM3List
} from '../features/app/context/device-sidebar/device-state.types';
import {v4 as uuidv4} from 'uuid';
import {EMvsGeoStatus} from '../features/app/context/mbb-admin-web/components/VehicleData/VehicleData.types';

export const mapDeviceStateTBM3List = (apiDeviceStateTBM3List: ApiDeviceStateTBM3List): DeviceStateTBM3List => {
    return {
        items: apiDeviceStateTBM3List.device_states.map(mapDeviceStateTBM3),
        cursorNext: apiDeviceStateTBM3List?.paging?.cursor_next
    };
};

export const mapDeviceStateMBBList = (apiDeviceStateMBBList: ApiDeviceStateMBBList): DeviceStateMBBList => {
    return {
        items: apiDeviceStateMBBList.device_states.map(mapDeviceStateMBB),
        cursorNext: apiDeviceStateMBBList?.paging?.cursor_next
    };
};

const getDeviceTypeByOriginator = (originator: string): DEVICE_TYPE => {
    if (originator?.toUpperCase().startsWith(DEVICE_TYPE.VCM)) {
        return DEVICE_TYPE.VCM;
    } else if (originator?.toUpperCase().startsWith(DEVICE_TYPE.OCU3)) {
        return DEVICE_TYPE.OCU3;
    } else if (originator?.toUpperCase().startsWith(DEVICE_TYPE.CM4)) {
        return DEVICE_TYPE.CM4;
    } else {
        return DEVICE_TYPE.TBM3;
    }
};

export const mapDeviceStateTBM3 = (apiDeviceStateTBM3: ApiDeviceStateTBM3): DeviceStateTBM3 => {
    const {
        box_serial_number,
        vin,
        builtin_position,
        gateway,
        hardware_region,
        hardware_variant,
        hardware_version,
        last_mileage,
        last_mileage_date_time,
        last_online_date_time,
        originator,
        previous_vin,
        software_version,
        tco_info,
        vehicle_type,
        vehicle_variant,
        status,
        mileage_check_skipped,
        account_id,
    } = apiDeviceStateTBM3;
    return {
        id: uuidv4(),
        serialNumber: box_serial_number,
        vin,
        type: getDeviceTypeByOriginator(originator),
        gateway,
        builtinPosition: {
            serialNumber: builtin_position?.box_serial_number,
            builtinDateTime: builtin_position?.builtin_date_time,
            latitude: builtin_position?.latitude,
            longitude: builtin_position?.longitude,
            positionDateTime: builtin_position?.position_date_time,
        },
        hardwareRegion: hardware_region,
        hardwareVariant: hardware_variant,
        hardwareVersion: hardware_version,
        lastMileage: last_mileage,
        lastMileageDateTime: last_mileage_date_time,
        lastOnlineDateTime: new Date(last_online_date_time).getTime(),
        originator,
        previousVin: previous_vin,
        softwareVersion: software_version,
        tcoInfo: {
            supplier: tco_info?.supplier,
            variant: tco_info?.variant,
            hardwareNumber: tco_info?.hardware_number,
            softwareNumber: tco_info?.software_number
        },
        vehicleType: vehicle_type,
        vehicleVariant: vehicle_variant,
        status,
        mileageCheckSkipped: mileage_check_skipped,
        accountId: account_id
    };
};

export const mapDeviceStateMBB = (apiDeviceStateMBB: ApiDeviceStateMBB): DeviceStateMBB => {
    const {
        deviceId,
        mbbRegistryRegistrationStatus,
        mfhDeviceRegistrationStatus,
        mvsGeoStatus,
        tssSubscriptionStatus,
        vin,
        lastOnlineDateTime,
        status,
        mileageCheckSkipped,
        accountId
    } = apiDeviceStateMBB;
    const errorState =
        (mvsGeoStatus === EMvsGeoStatus.OML_MODE || mvsGeoStatus === EMvsGeoStatus.OFF) ? '' : mvsGeoStatus;
    return {
        id: uuidv4(),
        serialNumber: deviceId,
        originator: DEVICE_TYPE.OCU3 + '|' + deviceId,
        vin,
        type: DEVICE_TYPE.OCU3,
        mbbRegistryRegistrationStatus,
        mfhDeviceRegistrationStatus,
        mvsGeoStatus,
        tssSubscriptionStatus,
        lastOnlineDateTime: new Date(lastOnlineDateTime).getTime(),
        status,
        errorState,
        mileageCheckSkipped,
        accountId
    };
};
