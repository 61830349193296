import React from 'react';
import { PropertiesTable } from '../../../../../components/table/PropertiesTable';
import { deviceStateMBBDetailsRowDescriptors, } from '../data-descriptors/deviceStateDetailsRowDescriptors';
import { DeviceStateMBB } from '../device-state.types';
import { FormattedMessage } from 'react-intl';
import {
    useFetchDeviceMBBRegistryQuery,
    useFetchDeviceMBBRegistryStatesQuery,
    useLazyVsrCheckQuery,
} from '../../../../../services/deviceStateApi';
import merge from 'lodash/fp/merge';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

const tableClassName = 'table table-condensed table-hover';

interface DeviceStateDetailsMBBProps {
    selectedDevice: DeviceStateMBB | undefined;
}

export const DeviceStateDetailsMBB = ({selectedDevice}: DeviceStateDetailsMBBProps) => {
    const {data: dataMBBRegistry, isLoading: isMBBRegistryLoading} = useFetchDeviceMBBRegistryQuery(
        {vin: selectedDevice?.vin}
    );
    const {data: dataMBBRegistryState, isLoading: isMBBRegistryStateLoading} = useFetchDeviceMBBRegistryStatesQuery(
        {vin: selectedDevice?.vin}
    );
    const [trigger, result] = useLazyVsrCheckQuery();
    const {data, error, isError, isLoading, isFetching} = result;

    const displayVSRCheckData = () => {
        if (isError) {
            return (
                <div>
                    <span>Could not fetch data from VSR with HttpStatus: {error}</span>
                </div>
            );
        } else {
            if (!!data) {
                let errorMessage;
                switch (data.error.errorCode) {
                    case EVSRErrorCodes.MISSING_RIGHTS_ERROR_CODE:
                    case EVSRErrorCodes.SERVICE_LOCALLY_DISABLED_ERROR_CODE:
                        errorMessage = 'Please check privacy mode in the vehicle.';
                        break;
                    case EVSRErrorCodes.MISSING_RIGHTS_UNKNOWN_SERVICE_ERROR_CODE:
                        errorMessage = 'This vehicle is not a TGE or does not have an OCU3 installed.';
                        break;
                    case EVSRErrorCodes.UNKNOWN:
                        errorMessage = 'Unexpected error received from VSR.';
                        break;
                    default:
                        errorMessage = 'Should not be visible.';
                        break;
                }
                return (
                    <div>
                        <span><strong>{errorMessage}</strong></span>
                        <pre>
                            <span>Raw response from VSR:</span>
                            <br/>
                            {JSON.stringify(data, null, 2)}
                        </pre>
                    </div>
                );
            } else {
                return (
                    <div>
                        <span>Successfully fetched mileage for vehicle.</span>
                    </div>
                );
            }
        }
    };

    const expanderPanelColor = () => {
        if (isError) {
            return 'danger';
        } else {
            return !!data ? 'warning' : 'success';
        }
    };

    return <>
        <h6 className="text-color-info padding-10">
            <FormattedMessage id={'intl-msg:coreconAdminWeb.noteMBBDevices'} values={{br: <br/>}}/>
        </h6>
        {isMBBRegistryLoading || isMBBRegistryStateLoading ? <Spinner text={'Loading'}/> :
            <PropertiesTable showHeader={false} className={tableClassName}
                             data={merge(dataMBBRegistryState, dataMBBRegistry)}
                             rowDescriptors={deviceStateMBBDetailsRowDescriptors}/>
        }
        <button type={'button'}
                style={{marginTop: '5px'}}
                className={'btn btn-primary btn-icon-right  margin-top-10 margin-bottom-20'}
                disabled={isLoading || isFetching}
                onClick={() => trigger(selectedDevice!.vin!)}>
            <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
            VSR Check
        </button>
        {
            !result.isUninitialized &&
            <div>
                {isLoading || isFetching ?
                    (
                        <Spinner text={'Loading'}/>
                    )
                    :
                    (
                        <div>
                            <div className={'col-12 col-sm-4 margin-bottom-20'}>
                                <ExpanderPanel title={'VSR Check result'} bsStyle={expanderPanelColor()} open>
                                    {displayVSRCheckData()}
                                </ExpanderPanel>
                            </div>
                        </div>
                    )
                }
            </div>
        }
    </>;
};

enum EVSRErrorCodes {
    MISSING_RIGHTS_ERROR_CODE = 'mbbc.rolesandrights.unauthorized',
    SERVICE_LOCALLY_DISABLED_ERROR_CODE = 'mbbc.rolesandrights.servicelocallydisabled',
    MISSING_RIGHTS_UNKNOWN_SERVICE_ERROR_CODE = 'mbbc.rolesandrights.unknownService',
    UNKNOWN = 'unknown'
}
