import {truncate} from '../../../../../components/utils/appsUtils';
import {ColumnDescriptorProps} from '../../../../../components/table/ListTable';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/Popover';
import {FieldOrNoData} from '../../../../../components/PropertyTableFieldDefinitions';
import React from 'react';

import {FileDownloader} from './FileDownloader';
import {fetchContinentalImportedFile} from '../../../../../services/journalContinentalStateApi';
import {fetchSREImportedFile} from '../../../../../services/journalStoneridgeStateApi';
import {fetchNasysImportedFile} from '../../../../../services/journalNasysStateApi';

export const nasysColumnDescriptors: ColumnDescriptorProps<any>[] = [
    {
        id: 'downloadFile',
        field: 'downloadFile',
        label: 'intl-msg:coreconAdminWeb.journal.download',
        className: 'min-width-150',
        format: (field, item) => [
            <span key={'nasysDownloadText'}>Download file</span>,
            <FileDownloader key={'downloadNasysButton'}
                            filename={item.importDate}
                            downloadMethod={fetchNasysImportedFile}/>]
    },
    {
        id: 'invalidDevices',
        field: 'invalidDevices',
        label: 'intl-msg:coreconAdminWeb.journal.invalid',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'duplicatedDevices',
        field: 'duplicatedDevices',
        label: 'intl-msg:coreconAdminWeb.journal.duplicated',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'savedDevices',
        field: 'savedDevices',
        label: 'intl-msg:coreconAdminWeb.journal.saved',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'status',
        field: 'status',
        label: 'intl-msg:coreconAdminWeb.journal.status',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'fileDate',
        field: 'fileDate',
        label: 'intl-msg:coreconAdminWeb.journal.fileDate',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
   }, {
        id: 'importDate',
        field: 'importDate',
        label: 'intl-msg:coreconAdminWeb.journal.date',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    },
];

export const renderErrorMessage = (message: string): [string, JSX.Element] => {
    const messages = message.split('\n');
    const renderedMessages: JSX.Element[] = [];
    for (let i = 1; i < messages.length; i++) {
        renderedMessages.push(
            <li>
                <div className="display-flex gap-5 margin-bottom-10">
                    <span>{messages[i]}</span>
                </div>
            </li>
        );
    }
    const title = messages[0].replace(':', '');
    return [title, <ul key={title} className="tree-list">{renderedMessages}</ul>];
};

const handleStoneridgeMessage = (message: string | number | symbol) => {
    const messageString = message.toString();
    if (!messageString.includes('\n')) {
        return <span>{message}</span>;
    }
    const [title, errorMessages] = renderErrorMessage(messageString);
    return <OverlayTrigger placement='auto' trigger={'click'} overlay={
        <Popover contentClassName={'max-height-400 overflow-y-scroll'} id={'popover-positioned'}
                 title={title}
                 placement={'auto'}>
            {errorMessages}
        </Popover>
    }>
        <div>
            <a>{truncate(messageString as string, 100)}</a>
        </div>
    </OverlayTrigger>;
};

export const stoneridgeColumnDescriptors: ColumnDescriptorProps<any>[] = [
    {
        id: 'filename',
        field: 'filename',
        label: 'intl-msg:coreconAdminWeb.journal.filename',
        className: 'min-width-150',
        format: (field) => [
            <FieldOrNoData key="fieldOrNoData" field={field}/>,
            <FileDownloader key={'sreFileDownloadButton'} filename={String(field)}
                            downloadMethod={fetchSREImportedFile}/>,
        ],
    }, {
        id: 'savedDevices',
        field: 'savedDevices',
        label: 'intl-msg:coreconAdminWeb.journal.saved',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'status',
        field: 'status',
        label: 'intl-msg:coreconAdminWeb.journal.status',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'importDate',
        field: 'importDate',
        label: 'intl-msg:coreconAdminWeb.journal.date',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'isUpdate',
        field: 'isUpdate',
        label: 'intl-msg:coreconAdminWeb.journal.isUpdate',
        className: 'min-width-150',
        format: (field) => <span>{field.toString()}</span>,
    }, {
        id: 'message',
        field: 'message',
        label: 'intl-msg:coreconAdminWeb.journal.message',
        className: 'min-width-150',
        format: (field) => handleStoneridgeMessage(field),
    },
];

export const continentalColumnDescriptors: ColumnDescriptorProps<any>[] = [
    {
        id: 'filename',
        field: 'filename',
        label: 'intl-msg:coreconAdminWeb.journal.filename',
        className: 'min-width-150',
        format: (field) => [<FieldOrNoData key="fieldOrNoData" field={field}/>,
            <FileDownloader key={'contiFileDownloadButton'} filename={String(field)}
                            downloadMethod={fetchContinentalImportedFile}/>,
        ],
    }, {
        id: 'savedDevices',
        field: 'savedDevices',
        label: 'intl-msg:coreconAdminWeb.journal.saved',
        className: 'min-width-50',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'status',
        field: 'status',
        label: 'intl-msg:coreconAdminWeb.journal.status',
        className: 'min-width-50',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'importDate',
        field: 'importDate',
        label: 'intl-msg:coreconAdminWeb.journal.date',
        className: 'min-width-100',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'isUpdate',
        field: 'isUpdate',
        label: 'intl-msg:coreconAdminWeb.journal.isUpdate',
        className: 'min-width-50',
        format: (field) => <span>{field.toString()}</span>,
    }, {
        id: 'user',
        field: 'user',
        label: 'intl-msg:coreconAdminWeb.journal.user',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    }, {
        id: 'message',
        field: 'message',
        label: 'intl-msg:coreconAdminWeb.journal.message',
        className: 'min-width-150',
        format: (field) => <span>{truncate(field as string, 100)}</span>,
    },
];
