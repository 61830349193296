export interface VehicleDataState {
  vin: { value: string; isValid: boolean };
  showResults?: boolean;
  disable?: boolean;
  vehicleData?: VehicleStateModel;
}

export enum EDeviceRegistrationStatus {
  REGISTERED,
  UN_REGISTERED
}

export enum EMvsGeoStatus {
  NORMAL_MODE = 'NORMAL_MODE',
  OML_MODE= 'OML_MODE',
  OFF='OFF',
  ERROR='ERROR'
}

export enum ETssSubscriptionStatus {
  SUBSCRIBED,
  UN_SUBSCRIBED
}

export enum EMfhDeviceRegistrationStatus {
  REGISTERED,
  UN_REGISTERED
}

export interface VehicleStateModel {
  vin: string;
  deviceId: string;
  tssSubscriptionStatus: ETssSubscriptionStatus;
  mfhDeviceRegistrationStatus: EMfhDeviceRegistrationStatus;
  mvsGeoStatus: EMvsGeoStatus;
  deviceRegistrationStatus: EDeviceRegistrationStatus;
}

export interface DeviceStateDataModel {
  vin: string;
  deviceId: string;
  tssSubscriptionStatus: ETssSubscriptionStatus;
  mvsGeoStatus: EMvsGeoStatus;
}

export interface RegistryDataModel {
  vin: string;
  mfhDeviceRegistrationStatus: EMfhDeviceRegistrationStatus;
  mbbRegistryRegistrationStatus: EDeviceRegistrationStatus;
}

export interface SubscriptionChangeRequest<T> {
  vin: string;
  newStatus: T;
  oldStatus: T;
}
