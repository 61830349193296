import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import {config} from '../config';
import {accessToken} from '../configuration/tokenHandling/accessToken';
import {AccessToken} from '../configuration/tokenHandling/tokenSlice';
import {downloadFileFromAPICall} from './journalUtils';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};


export const fetchNasysImportedFile = (props: {
    filename: string;
    setErrorMessage: (message: string) => void;
}) => {
    if (config.backend.DEVICE_INIT_MONITOR_SERVICE === undefined) {
        throw new Error('Device init monitor service property not defined!');
    }

    let url = config.backend.DEVICE_INIT_MONITOR_SERVICE;
    if (url[url.length - 1] !== '/') {
        url = url.concat('/');
    }
    url = url.concat('nasys-file-history/download-imported-file');
    url = url.concat(`?${qs.stringify({
            importedDate: props.filename
        }, {
            skipNulls: true,
            encode: true,
        }
    )}`);
    downloadFileFromAPICall({url, filename: props.filename + '.csv', setErrorMessage: props.setErrorMessage});
};


// Define a service using a base URL and expected endpoints
export const journalNasysStateApi = createApi({
    reducerPath: 'journalNasys',
    tagTypes: ['JournalNasysState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_INIT_MONITOR_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchJournalNasysStates: builder.query<any, {
            page: number;
        }>({
            query: ({page}) => ({
                url: `/nasys-file-history?${qs.stringify({page}, {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
                responseHandler: async (response: Response) => response.json(),
            }),
            providesTags: ['JournalNasysState'],
        }),
    }),
});

// Export hooks for usage in function components which are
export const {useFetchJournalNasysStatesQuery} = journalNasysStateApi;
