import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import size from 'lodash/fp/size';

import TableSettingsDialog from '@rio-cloud/rio-uikit/lib/es/TableSettingsDialog';
import { DefaultWhiteColumn } from '../../../../../components/DefaultWhiteColumn';
import { DeviceListTable } from './DeviceListTable';
import {
    CONTROL_DEVICE_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY,
    deviceColumnDescriptors
} from '../../device-sidebar/data-descriptors/deviceDetailsColumnDescriptors';
import { useFetchDeviceMBBStatesQuery, useFetchDeviceTBM3StatesQuery } from '../../../../../services/deviceStateApi';
import { getAllDeviceStatesCommon } from '../../../appSlice';
import { useAppSelector } from '../../../../../configuration/setup/hooks';
import { DeviceStateCommon, Status } from '../../device-sidebar/device-state.types';
import { DeviceListTableSearch } from './DeviceListTableSearch';

const DEFAULT_LIMIT: number = 100;

export interface DeviceListFilter {
    serialNumberPrefix: string;
    deviceIdPrefix: string;
    vinPrefix: string;
    withoutMileage: boolean;
    status: Status;
    accountIdPrefix: string;
}

export interface DeviceListPage {
    limit: number;
    cursorTBM3: string | undefined;
    cursorMBB: string | undefined;
}

export const DeviceList = () => {
    const {formatMessage} = useIntl();
    const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
    const [page, setPage] = useState<DeviceListPage>({
        limit: DEFAULT_LIMIT,
        cursorTBM3: undefined,
        cursorMBB: undefined,
    });
    const [filter, setFilter] = useState<DeviceListFilter>({
        serialNumberPrefix: '',
        deviceIdPrefix: '',
        vinPrefix: '',
        withoutMileage: false,
        status: Status.EMPTY,
        accountIdPrefix: '',
    });
    const [columnOrder, setColumnOrder] = useState<string[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [defaultHiddenColumns, setDefaultHiddenColumns] = useState([]);
    const [columnsDetails, setColumnsDetails] = useState();
    const [defaultColumnLabels, setDefaultColumnLabels] = useState();
    const defaultColumnLabelsMap = {};
    const {data: dataTBM3, isFetching: isTBM3Loading, refetch: refetchTBM3} = useFetchDeviceTBM3StatesQuery(
        {filterTBM3: filter, page},
    );
    const {data: dataMBB, isFetching: isMBBLoading, refetch: refetchMBB} = useFetchDeviceMBBStatesQuery(
        {filterMBB: filter, page},
    );
    const isLoading = isTBM3Loading || isMBBLoading;
    const deviceStateDetails: DeviceStateCommon[] = useAppSelector(getAllDeviceStatesCommon);

    useEffect(() => {
        try {
            const storedColumnSettingsJSON = localStorage.getItem(
                CONTROL_DEVICE_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY
            );
            if (storedColumnSettingsJSON) {
                const storedColumnSettings = JSON.parse(storedColumnSettingsJSON);
                // reset locally stored column settings if new columns settings were added
                if (storedColumnSettings && (size(storedColumnSettings.columnOrder) !== size(
                    deviceColumnDescriptors))) {
                    localStorage.removeItem(CONTROL_DEVICE_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY);
                } else {
                    deviceColumnDescriptors.forEach((entry) => {
                        // @ts-ignore
                        defaultColumnLabelsMap[entry.id] = formatMessage({id: entry.label});
                    });
                    setColumnOrder(Object.keys(defaultColumnLabelsMap) as string[]);
                }
                if (storedColumnSettings.columnOrder) {
                    setColumnOrder(storedColumnSettings.columnOrder);
                }
                if (storedColumnSettings.hiddenColumns) {
                    setHiddenColumns(storedColumnSettings.hiddenColumns);
                }
            } else {
                const extractedHiddenColumns: string[] = [];
                deviceColumnDescriptors.forEach(entry => {
                    // @ts-ignore
                    defaultColumnLabelsMap[entry.id] = formatMessage({id: entry.label});
                    if (entry.hidden) {
                        extractedHiddenColumns.push(entry.id);
                    }
                });
                setColumnOrder(Object.keys(defaultColumnLabelsMap));
                setHiddenColumns(extractedHiddenColumns);
            }
            // @ts-ignore
            setDefaultColumnLabels(defaultColumnLabelsMap);
            const defaultHiddenColumnsRetrieved = deviceColumnDescriptors.map((column) => {
                if (column.hidden) {
                    return column.id;
                }
            });
            // @ts-ignore
            setDefaultHiddenColumns(defaultHiddenColumnsRetrieved);
        } catch (e) {
            console.error(e);
        }
        return () => {
        };
    }, []);

    useEffect(() => {
        if (dataTBM3?.cursorNext) {
            refetchTBM3();
        }
        if (dataMBB?.cursorNext) {
            refetchMBB();
        }
    }, [page]);

    const handleLoadMore = () => {
        setPage({
            ...page,
            // cursor: data?.cursorNext || '',
        });
    };

    const handleColumnDetailsChange = (column: string, newColumnDetails: any) => {
        // @ts-ignore
        const updatedColumnsDetails = {...columnsDetails};
        updatedColumnsDetails[column] = newColumnDetails;
        setColumnsDetails(updatedColumnsDetails);
    };

    const handleColumnChange = (newColumnOrder: any, newHiddenColumns: [], newColumnsDetails: any) => {
        setColumnOrder(newColumnOrder);
        setHiddenColumns(newHiddenColumns);
        setColumnsDetails(newColumnsDetails);
        localStorage.setItem(CONTROL_DEVICE_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify({
            columnOrder: newColumnOrder,
            hiddenColumns: newHiddenColumns,
            columnsDetails: newColumnsDetails,
        }));
    };

    const renderTableSettingsDialog = () => {
        // @ts-ignore
        const defaultColumnOrder = Object.keys(defaultColumnLabels);
        // create function to recover from browser storage
        return (
            <TableSettingsDialog
                show={showTableSettingsDialog}
                title={formatMessage({id: 'intl-msg:coreconAdminWeb.tableSettings'})}
                onHide={() => setShowTableSettingsDialog(false)}
                onColumnChange={handleColumnChange}
                defaultColumnOrder={defaultColumnOrder}
                defaultHiddenColumns={defaultHiddenColumns}
                columnOrder={columnOrder}
                hiddenColumns={hiddenColumns}
                columnLabels={defaultColumnLabels}
                closeButtonText={formatMessage({id: 'intl-msg:coreconAdminWeb.close'})}
                resetButtonText={formatMessage({id: 'intl-msg:coreconAdminWeb.reset'})}
                searchPlaceholder={formatMessage({id: 'intl-msg:coreconAdminWeb.searchByColumnName'})}
                notFoundMessage={formatMessage({id: 'intl-msg:coreconAdminWeb.errorNoColumnFound'})}
                columnsDetails={columnsDetails}
                autoLabel={formatMessage({id: 'intl-msg:coreconAdminWeb.auto'})}
                onColumnDetailsChange={handleColumnDetailsChange}
                immediateChange
                disabledColumns={''}
            />
        );
    };

    return (
        <>
            <DefaultWhiteColumn className="padding-10">
                <DeviceListTableSearch
                    filter={filter}
                    setFilter={setFilter}
                    setShowTableSettingsDialog={setShowTableSettingsDialog}
                    isLoading={isLoading}/>
            </DefaultWhiteColumn>
            <DefaultWhiteColumn className="padding-top-20 padding-bottom-5 user-select-none
            overflow-auto max-height-1000">
                <DeviceListTable
                    devices={deviceStateDetails}
                    hiddenColumns={hiddenColumns}
                    isLoading={isLoading}
                    columnOrder={columnOrder}/>
            </DefaultWhiteColumn>
            {(dataTBM3?.cursorNext || dataMBB?.cursorNext) &&
                <DefaultWhiteColumn key={'loadMore'} className="text-center padding-bottom-20">
                    <a id="load-more-button"
                       className="btn btn-default"
                       onClick={handleLoadMore}>
                        <FormattedMessage id="intl-msg:coreconAdminWeb.loadMore"/>
                    </a>
                </DefaultWhiteColumn>
            }
            {showTableSettingsDialog && renderTableSettingsDialog()}
        </>
    );
};
