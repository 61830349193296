import { ColumnDescriptorProps } from '../../../../../components/table/ListTable';
import { ServiceCertificate } from '../certificates.types';
export const columnDescriptors: ColumnDescriptorProps<ServiceCertificate>[] = [
    {
        id: 'host',
        field: 'host',
        label: 'intl-msg:coreconAdminWeb.serviceCertificates.host',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    },
    {
        id: 'service',
        field: 'service',
        label: 'intl-msg:coreconAdminWeb.serviceCertificates.service',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    },
    {
        id: 'expirationDate',
        field: 'expirationDate',
        label: 'intl-msg:coreconAdminWeb.serviceCertificates.expirationDate',
        className: 'min-width-150',
        format: (field) => <span>{new Date(field).toLocaleDateString()}</span>,
    },
    {
        id: 'remainingDays',
        field: 'remainingDays',
        label: 'intl-msg:coreconAdminWeb.serviceCertificates.remainingDays',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    },
    {
        id: 'createdBy',
        field: 'createdBy',
        label: 'intl-msg:coreconAdminWeb.serviceCertificates.createdBy',
        className: 'min-width-150',
        format: (field) => <span>{field}</span>,
    },
];
