import React from 'react';
import { copyToClipboard, SortDirection } from '../../../../../components/utils/appsUtils';
import { FormattedTime } from 'react-intl';
import Position from '@rio-cloud/rio-uikit/lib/es/Position';
import { DEVICE_TYPE, DeviceStateCommon, Status } from '../device-state.types';
import { FieldOrNoData, NoData } from '../../../../../components/PropertyTableFieldDefinitions';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import { ColumnDescriptorProps } from '../../../../../components/table/ListTable';

export const CONTROL_DEVICE_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY =
    'CONTROL_DEVICE_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY';

const renderOnlineIcon = (item?: DeviceStateCommon) => {
    if (!item) {
        return <NoData/>;
    }
    switch (item.status) {
        case Status.ONLINE:
            return <div className="text-danger text-center"><span
                className="badge bg-success"><span
                className="rioglyph rioglyph-ok-sign"/></span></div>;
        case Status.OFFLINE:
            return <div className="text-danger text-center"><span
                className="badge bg-danger"><span
                className="rioglyph rioglyph-remove-sign"/></span></div>;
        case Status.INACTIVE:
            return <div className="text-danger text-center"><span className="badge bg-lighter">
                        <span className="rioglyph rioglyph-minus"/></span></div>;
        default:
            return <NoData/>;
    }
};

const displayVinInformation = (item?: DeviceStateCommon) => {
    if (!item) {
        return;
    }
    const isTBM3orVCMorCM4 =
        item.type === DEVICE_TYPE.CM4 || item.type === DEVICE_TYPE.TBM3 || item.type === DEVICE_TYPE.VCM;
    return (
        item.vin &&
        <div className={'device-state-vin-inner'}>
            {
                <div>{item.vin}</div>
            }
            {
                (isTBM3orVCMorCM4 && !!item.previousVin) &&
                    <div>
                        <s>{item.previousVin}</s>
                    </div>
            }
        </div>
    );
};

const copyToClipboardHandler = (event: React.MouseEvent<HTMLSpanElement>, field: string | number) => {
    event.stopPropagation();
    copyToClipboard(field as string, true);
};

const statusSortingFn = (a: DeviceStateCommon, b: DeviceStateCommon, sortingDir: SortDirection) => {
    const getStatusOrder = (status?: Status): number => {
        // status-less values at the bottom always
        const emptyValue = sortingDir === SortDirection.ASCENDING ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
        switch (status) {
            case Status.ONLINE:
                return 1;
            case Status.OFFLINE:
                return 2;
            case Status.INACTIVE:
                return 3;
            default:
                return emptyValue;
        }
    };

    const comparison = getStatusOrder(a.status) - getStatusOrder(b.status);
    return sortingDir === SortDirection.ASCENDING ? comparison : -comparison;
};

export const deviceColumnDescriptors: ColumnDescriptorProps<DeviceStateCommon>[] = [
    {
        id: 'type',
        field: 'type',
        label: 'intl-msg:coreconAdminWeb.type',
        className: 'text-center width-50',
        format: (field) => {
            return <div className="label label-primary label-condensed">{field}</div>;
        },
    }, {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'intl-msg:coreconAdminWeb.deviceId',
        format: (field) => [
            <FieldOrNoData key={field + 'OrNoData'} field={field}/>,
            <>
                {
                    field &&
                    <span key={field}
                          className=
                              "rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5 mouse-pointer"
                          onClick={(event) => copyToClipboardHandler(event, field)}/>
                }
            </>
        ],
    }, {
        id: 'vin',
        field: 'vin',
        label: 'intl-msg:coreconAdminWeb.vin',
        className: 'hidden-xs hidden-ls hidden-sm device-state-vin',
        format: (field, item?: DeviceStateCommon) => [
            <FieldOrNoData key={field + 'OrNoData'} field={displayVinInformation(item)}/>,
            <>
                {
                    field &&
                    <div className={'device-state-vin-inner'}>
                    <span key={field}
                          className=
                              "rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5 mouse-pointer"
                          onClick={(event) => copyToClipboardHandler(event, field)}/>
                    </div>
                }
            </>
        ],
    }, {
        id: 'accountId',
        field: 'accountId',
        label: 'intl-msg:coreconAdminWeb.accountId',
        className: '',
        format: (field) => [
            <FieldOrNoData key={field + 'OrNoData'} field={field}/>,
            <>
                {
                    field &&
                    <span key={field}
                          className=
                              "rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5 mouse-pointer"
                          onClick={(event) => copyToClipboardHandler(event, field)}/>
                }
            </>,
        ],
    }, {
        id: 'status',
        field: 'status',
        label: 'intl-msg:coreconAdminWeb.status',
        format: (field, item?: DeviceStateCommon) => (
            <>
                {
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{marginRight: '5px'}}>
                            {
                                renderOnlineIcon(item)
                            }
                        </div>
                        <div>
                            {
                                !!item?.errorState &&
                                <div>
                                    <OverlayTrigger placement={'top'} overlay={
                                        <Tooltip id='tooltip' allowOnTouch>
                                            {item.errorState}
                                        </Tooltip>
                                    }
                                    >
                                        <div className="text-warning text-center">
                                <span className="badge bg-warning">
                                    <span className="rioglyph rioglyph-warning-sign"/>
                                </span>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
        ),
        // sortable: true,
        // sortingFn: statusSortingFn
    }, {
        id: 'vehicleVariant',
        field: 'vehicleVariant',
        label: 'intl-msg:coreconAdminWeb.vehicleVariant',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'softwareVersion',
        field: 'softwareVersion',
        label: 'intl-msg:coreconAdminWeb.softwareVersion',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'hardwareVersion',
        field: 'hardwareVersion',
        label: 'intl-msg:coreconAdminWeb.hardwareVersion',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'hardwareVariant',
        field: 'hardwareVariant',
        label: 'intl-msg:coreconAdminWeb.hardwareVariant',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'lastMileage',
        field: 'lastMileage',
        label: 'intl-msg:coreconAdminWeb.mileage',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'lastMileageDate',
        field: 'lastMileageDateTime',
        label: 'intl-msg:coreconAdminWeb.mileageDate',
        format: (field) => {
            return (
                <FieldOrNoData field={field ?
                    <FormattedTime value={field} year="numeric" month="2-digit" day="2-digit"/> : null}/>
            );
        },
        hidden: true,
    }, {
        id: 'lastOnlineDate',
        field: 'lastOnlineDateTime',
        label: 'intl-msg:coreconAdminWeb.lastMessage',
        format: (field) => {
            return (
                <FieldOrNoData field={field ?
                    <FormattedTime value={field} year="numeric" month="2-digit" day="2-digit"/> : null}/>
            );
        },
        hidden: true,
    }, {
        id: 'builtInPosition',
        field: 'builtinPosition',
        label: 'intl-msg:coreconAdminWeb.builtInPosition',
        format: (field: any) => {
            const latitude = field?.latitude;
            const longitude = field?.longitude;
            if (latitude && longitude) {
                return (
                    <a target="_blank"
                       href={`https://share.here.com/l/${latitude},${longitude}`}
                       rel="noreferrer noopener">
                        <Position latitude={parseFloat(latitude)} longitude={parseFloat(longitude)}/>
                    </a>
                );
            } else {
                return <NoData/>;
            }
        },
        hidden: true,
    }, {
        id: 'builtInDate',
        field: 'builtinPosition',
        label: 'intl-msg:coreconAdminWeb.builtInDate',
        format: (field: any) => {
            return (
                <FieldOrNoData field={field?.builtinDateTime ?
                    <FormattedTime value={field?.builtinDateTime} year="numeric" month="2-digit" day="2-digit"/>
                    : null}/>
            );
        },
        hidden: true,
    }, {
        id: 'positionDate',
        field: 'builtinPosition',
        label: 'intl-msg:coreconAdminWeb.positionDate',
        format: (field: any) => {
            return (
                <FieldOrNoData field={field?.positionDateTime ?
                    <FormattedTime value={field?.positionDateTime} year="numeric" month="2-digit" day="2-digit"/>
                    : null}/>
            );
        },
        hidden: true,
    }, {
        id: 'hardwareRegion',
        field: 'hardwareRegion',
        label: 'intl-msg:coreconAdminWeb.hardwareRegion',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'vehicleType',
        field: 'vehicleType',
        label: 'intl-msg:coreconAdminWeb.vehicleType',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'gateway',
        field: 'gateway',
        label: 'intl-msg:coreconAdminWeb.gateway',
        format: (field) => (
            <span className="white-space-nowrap">
                <span className="margin-right-5">
                    <FieldOrNoData field={field}/>
                </span>
            </span>
        ),
        hidden: true,
    }, {
        id: 'tcoSupplier',
        field: 'tcoInfo',
        label: 'intl-msg:coreconAdminWeb.tcoSupplier',
        format: (field: any) => {
            return (
                <span className="white-space-nowrap">
                    <span className="margin-right-5">
                        <FieldOrNoData field={field?.supplier}/>
                    </span>
                </span>
            );
        },
        hidden: true,
    }, {
        id: 'tcoVariant',
        field: 'tcoInfo',
        label: 'intl-msg:coreconAdminWeb.tcoVariant',
        format: (field: any) => {
            return (
                <span className="white-space-nowrap">
                    <span className="margin-right-5">
                        <FieldOrNoData field={field?.variant}/>
                    </span>
                </span>
            );
        },
        hidden: true,
    }, {
        id: 'tcoHardwareId',
        field: 'tcoInfo',
        label: 'intl-msg:coreconAdminWeb.tcoHardwareId',
        format: (field: any) => {
            return (
                <span className="white-space-nowrap">
                    <span className="margin-right-5">
                        <FieldOrNoData field={field?.hardwareNumber}/>
                    </span>
                </span>
            );
        },
        hidden: true,
    }, {
        id: 'tcoSoftwareId',
        field: 'tcoInfo',
        label: 'intl-msg:coreconAdminWeb.tcoSoftwareId',
        format: (field: any) => {
            return (
                <span className="white-space-nowrap">
                    <span className="margin-right-5">
                        <FieldOrNoData field={field?.softwareNumber}/>
                    </span>
                </span>
            );
        },
        hidden: true,
    }
];
