import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceListFilter } from './DeviceList';
import { resetDeviceStates } from '../../../appSlice';
import { ACCOUNT_OR_DEVICE_ID_LENGTH, SERIAL_NUMBER_LENGTH, VIN_LENGTH } from '../../../../../services/deviceStateApi';
import {Status} from '../../device-sidebar/device-state.types';

interface DeviceListTableSearchProps {
    filter: DeviceListFilter;
    setFilter: Function;
    setShowTableSettingsDialog: Function;
    isLoading: boolean;
}

export const DeviceListTableSearch = (props: DeviceListTableSearchProps) => {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const [searchPrefix, setSearchPrefix] = useState('');
    const [accountIdPrefix, setAccountIdPrefix] = useState('');


    const handleSearchValueChange = (searchPrefixValue: string) => {
        if (searchPrefixValue.trim().length === 0 && accountIdPrefix.length === 0) {
            dispatch(resetDeviceStates());
        }
        setSearchPrefix(searchPrefixValue.trim());
    };

    const handleAccountIdValueChange = (accountIdPrefixValue: string) => {
        if (accountIdPrefixValue.trim().length === 0 && searchPrefix.length === 0) {
            dispatch(resetDeviceStates());
        }
        setAccountIdPrefix(accountIdPrefixValue.trim());
    };

    const handleWithoutMileageChange = () => {
        dispatch(resetDeviceStates());
        props.setFilter({
            ...props.filter,
            withoutMileage: !props.filter.withoutMileage
        });
    };

    const handleStatusFilter = (status: Status) => {
        dispatch(resetDeviceStates());
        props.setFilter({
            ...props.filter,
            status: props.filter.status.valueOf() === status.valueOf() ? Status.EMPTY : status
        });
    };

    const handleSearchByPrefix = () => {
        dispatch(resetDeviceStates());
        props.setFilter({
            ...props.filter,
            serialNumberPrefix: searchPrefix.substring(0, SERIAL_NUMBER_LENGTH),
            deviceIdPrefix: searchPrefix.substring(0, ACCOUNT_OR_DEVICE_ID_LENGTH),
            vinPrefix: searchPrefix.substring(0, VIN_LENGTH),
            accountIdPrefix: accountIdPrefix.substring(0, ACCOUNT_OR_DEVICE_ID_LENGTH),
        });
    };

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="col-8">
                        <TableSearch
                            value={searchPrefix}
                            onChange={handleSearchValueChange}
                            placeholder={formatMessage({id: 'intl-msg:coreconAdminWeb.searchSerialOrVin'})}
                        />
                    </div>
                    <div className="col-4 padding-left-5">
                        <TableSearch
                            value={accountIdPrefix}
                            onChange={handleAccountIdValueChange}
                            placeholder={formatMessage({id: 'intl-msg:coreconAdminWeb.searchAccountId'})}/>
                    </div>
                    <div className="padding-left-5">
                        <Button active={!props.isLoading}
                                disabled={props.isLoading} onClick={handleSearchByPrefix}>
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.search'}/>
                        </Button>
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    <button disabled className="btn btn-default margin-right-5">
                        <span className="rioglyph rioglyph-download"/>
                        CSV
                    </button>
                    <button className="btn btn-default btn-icon-only"
                            onClick={() => props.setShowTableSettingsDialog(true)}>
                        <span className="rioglyph rioglyph-settings"/>
                    </button>
                </div>
            </div>
            <div className="padding-top-10">
                <Button className="margin-right-10" active={props.filter.withoutMileage}
                        onClick={handleWithoutMileageChange}>
                    <FormattedMessage id={'intl-msg:coreconAdminWeb.withoutMileage'}/>
                </Button>
                <Button className="margin-right-10" active={props.filter.status === Status.ONLINE &&
                    props.filter.status === Status.ONLINE}
                        onClick={() => handleStatusFilter(Status.ONLINE)}>
                    <FormattedMessage id={'intl-msg:coreconAdminWeb.online'}/>
                </Button>
                <Button className="margin-right-10" active={props.filter.status === Status.OFFLINE &&
                    props.filter.status === Status.OFFLINE}
                        onClick={() => handleStatusFilter(Status.OFFLINE)}>
                    <FormattedMessage id={'intl-msg:coreconAdminWeb.offline'}/>
                </Button>
                <Button className="margin-right-10" active={props.filter.status === Status.INACTIVE &&
                    props.filter.status === Status.INACTIVE}
                        onClick={() => handleStatusFilter(Status.INACTIVE)}>
                    <FormattedMessage id={'intl-msg:coreconAdminWeb.inactive'}/>
                </Button>
            </div>
        </TableToolbar>
    );
};
