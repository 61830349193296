import React from 'react';
import {FieldOrNoData} from '../../../../../components/PropertyTableFieldDefinitions';
import {ColumnDescriptorProps} from '../../../../../components/table/ListTable';
import {ApiDeviceSigningDataModelCM4, ApiDeviceSigningDataModelTBM3} from '../../device-sidebar/device-signing.types';
import {DEVICE_TYPE} from '../../device-sidebar/device-state.types';

export const deviceColumnDescriptors:
    ColumnDescriptorProps<ApiDeviceSigningDataModelTBM3 | ApiDeviceSigningDataModelCM4>[] = [
    {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'intl-msg:coreconAdminWeb.hwSerial',
        format: (field: any) => <FieldOrNoData key="fieldOrNoData" field={field}/>,
    }, {
        id: 'imei',
        field: 'imei',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.imei',
        format: (field: any, item: any) => <FieldOrNoData key="fieldOrNoData"
                                                          field={
                                                              item?.sreData?.tbm3_imei ||
                                                              item?.certData?.imei ||
                                                              item?.imei
                                                          }/>,
    }, {
        id: 'imsi',
        field: 'sreData',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.imsi',
        format: (field: any, item: any) => <FieldOrNoData key="fieldOrNoData"
                                                          field={
                                                              item?.sreData?.imsi ||
                                                              item?.certData?.imsi ||
                                                              item?.imsi
                                                          }/>,
    }, {
        id: 'iccid',
        field: 'iccid',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.iccid',
        format: (field: any, item: ApiDeviceSigningDataModelCM4 | ApiDeviceSigningDataModelTBM3 | undefined) =>
            <FieldOrNoData key="fieldOrNoData"
                           field={item?.deviceType === DEVICE_TYPE.CM4 ?
                               (item as ApiDeviceSigningDataModelCM4)?.iccid
                               :
                               (item as ApiDeviceSigningDataModelTBM3)?.sreData?.iccid ||
                               (item as ApiDeviceSigningDataModelTBM3)?.certData?.iccid}/>,
    }, {
        id: 'requestCnt',
        field: 'cntRejectedSign',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.numberOfRequests',
        format: (field: any, item: any) => <FieldOrNoData key="fieldOrNoData"
                                                          field={item?.cntRejectedSign || item?.cntFetchedSign}/>,
    }, {
        id: 'errorStatus',
        field: 'signingState',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.errorMessage',
        format: (field: any, item: any) => <FieldOrNoData key="fieldOrNoData" field={item?.signingState?.errorStatus}/>,
    }, {
        id: 'signingStatus',
        field: 'signingState',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.signingStatus',
        format: (field: any, item: any) => <FieldOrNoData key="fieldOrNoData"
                                                          field={item?.signingState?.signingStatus}/>,
    }
];
