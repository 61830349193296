import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import {config} from '../config';
import {accessToken} from '../configuration/tokenHandling/accessToken';
import {AccessToken} from '../configuration/tokenHandling/tokenSlice';
import {ContinentalDeviceInfo} from '../features/app/context/upload/upload/DeviceInformation';
import {UploadResult} from '../features/app/context/upload/upload/uploadResult';
import {downloadFileFromAPICall} from './journalUtils';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

export const fetchContinentalImportedFile = (props: {
    filename: string;
    setErrorMessage: (message: string) => void;
}) => {
    const serviceUrl = config.backend.DEVICE_SERVICE_CM4;
    if (serviceUrl === undefined) {
        throw new Error('Device service property not defined!');
    }

    let url = serviceUrl;
    if (serviceUrl[serviceUrl.length - 1] !== '/') {
        url = url.concat('/');
    }
    url = url.concat('download-supplier-imported-file');
    url = url.concat(`?${qs.stringify(props, {
            skipNulls: true,
            encode: true,
        }
    )}`);
    downloadFileFromAPICall({url, ...props});
};

// Define a service using a base URL and expected endpoints
export const journalContinentalStateApi = createApi({
    reducerPath: 'journalContinental',
    tagTypes: ['JournalContinentalState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE_CM4,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchJournalContinentalStates: builder.query<any, {
            page: number;
        }>({
            query: ({page}) => ({
                url: `/supplier-import-history?${qs.stringify({page}, {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
                responseHandler: async (response: Response) => response.json(),
            }),
            providesTags: ['JournalContinentalState'],
        }),
        uploadContinentalStates: builder.mutation<UploadResult, {
            devices: ContinentalDeviceInfo[];
            filename: string;
        }>({
            query: (devices) => ({
                url: `/metadata?filename=${devices.filename}`,
                method: 'POST',
                body: devices.devices,
                responseHandler: async (response: Response) => response.json(),
            }),
            invalidatesTags: ['JournalContinentalState']
        }),
    }),
});

// Export hooks for usage in function components which are
export const {useFetchJournalContinentalStatesQuery, useUploadContinentalStatesMutation} = journalContinentalStateApi;
