export interface ConfigState {
    backend: {
        DEVICE_STATE_SERVICE: string | undefined;
        DEVICE_INIT_MONITOR_SERVICE: string | undefined;
        DEVICE_SERVICE: string | undefined;
        DEVICE_SERVICE_CM4: string | undefined;
        DEVICE_STATE_TBM3_SERVICE: string | undefined;
        DEVICE_STATE_MBB_SERVICE: string | undefined;
        DEVICE_STATE_MBB_REGISTRY_SERVICE: string | undefined;
        SYSTEM_STATE_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
}

const getBoolEnvValue = (envValue: string): boolean => {
    return typeof process.env[envValue] !== 'undefined' && process.env[envValue] === 'true';
};

export const config: ConfigState = {
    backend: {
        DEVICE_STATE_SERVICE: process.env.REACT_APP_BACKEND_DEVICE_STATE_BASE_URL,
        DEVICE_INIT_MONITOR_SERVICE: process.env.REACT_APP_BACKEND_DEVICE_INIT_MONITOR_SERVICE_BASE_URL,
        DEVICE_SERVICE: process.env.REACT_APP_BACKEND_DEVICE_BASE_URL,
        DEVICE_SERVICE_CM4: process.env.REACT_APP_BACKEND_DEVICE_CM4_BASE_URL,
        DEVICE_STATE_TBM3_SERVICE: process.env.REACT_APP_BACKEND_DEVICE_STATE_BASE_URL,
        DEVICE_STATE_MBB_SERVICE: process.env.REACT_APP_MBB_CONNECTOR_DEVICE_STATE_BASE_URL,
        DEVICE_STATE_MBB_REGISTRY_SERVICE: process.env.REACT_APP_MBB_CONNECTOR_REGISTRY_BASE_URL,
        SYSTEM_STATE_SERVICE: process.env.REACT_APP_BACKEND_SYSTEM_STATE_BASE_URL
    },
    homeRoute: process.env.REACT_APP_HOME_ROUTE,
    id: process.env.REACT_APP_ID,
    login: {
        authority: process.env.REACT_APP_LOGIN_AUTHORITY,
        clientId: '395d6c94-7085-4068-9cfa-021cfe60b30d',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'mbb-connector.write',
            'vcp-connector.write',
            'user-managment.read',
            'fleetadmin.read'
        ],
        mockAuthorization: getBoolEnvValue('REACT_APP_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: process.env.REACT_APP_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('REACT_APP_LOGIN_PREVENT_REDIRECT'),
        redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
        silentRedirectUri: process.env.REACT_APP_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: process.env.REACT_APP_LOGOUT_URI,
    sentryToken: process.env.REACT_APP_SENTRY_DSN,
    sentryModuleName: 'starterTemplate',
};
