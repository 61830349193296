import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import {config} from '../config';
import {accessToken} from '../configuration/tokenHandling/accessToken';
import {AccessToken} from '../configuration/tokenHandling/tokenSlice';
import {UploadResult} from '../features/app/context/upload/upload/uploadResult';
import {downloadFileFromAPICall} from './journalUtils';

export const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
};


export const fetchSREImportedFile = (props: {
    filename: string;
    setErrorMessage: (message: string) => void;
}) => {
    if (config.backend.DEVICE_SERVICE === undefined) {
        throw new Error('Device service property not defined!');
    }

    let url = config.backend.DEVICE_SERVICE;
    if (config.backend.DEVICE_SERVICE[config.backend.DEVICE_SERVICE.length - 1] !== '/') {
        url = url.concat('/');
    }
    url = url.concat('stoneridge-download-imported-file');
    url = url.concat(`?${qs.stringify(props, {
            skipNulls: true,
            encode: true,
        }
    )}`);
    downloadFileFromAPICall({url, ...props});
};


// Define a service using a base URL and expected endpoints
export const journalStoneridgeStateApi = createApi({
    reducerPath: 'journalStoneridge',
    tagTypes: ['JournalStoneridgeState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchJournalStoneridgeStates: builder.query<any, {
            page: number;
        }>({
            query: ({page}) => ({
                url: `/stoneridge-import-history?${qs.stringify({page}, {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
                headers: {
                    'Content-type': 'application/json'
                },
                responseHandler: async (response: Response) => response.json(),
            }),
            providesTags: ['JournalStoneridgeState'],
        }),
        uploadStoneridgeMetadata: builder.mutation<UploadResult, {
            devices: string;
            filename: string;
        }>({
            query: (devices: { devices: string; filename: string }) => ({
                url: `/uploadFileTBM3DeviceInformation?filename=${devices.filename}`,
                method: 'POST',
                body: devices.devices,
                headers: {
                    'Content-type': 'text/csv'
                },
                responseHandler: async (response: Response) => response.json()
            })
        }),
    }),
});

// Export hooks for usage in function components which are
export const {
    useFetchJournalStoneridgeStatesQuery,
    useUploadStoneridgeMetadataMutation
} = journalStoneridgeStateApi;
