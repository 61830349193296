import React from 'react';
import {FormattedMessage, FormattedTime} from 'react-intl';
import {DEVICE_TYPE, DeviceStateMBB, DeviceStateTBM3} from './device-state.types';
import {FieldOrNoData} from '../../../../components/PropertyTableFieldDefinitions';

interface DeviceSummaryProps {
    serialNumber: string;
    type: DEVICE_TYPE;
    device: DeviceStateTBM3 | DeviceStateMBB | undefined;
}

export const DeviceSummary = ({serialNumber, type, device}: DeviceSummaryProps) => {

    const renderOnlineIcon = () => {
        switch (device?.status) {
            case 'ONLINE':
                return <div className="text-danger text-center"><span className="badge bg-success"><span
                    className="rioglyph rioglyph-ok-sign"/></span></div>;
            case 'OFFLINE':
                return <div className="text-danger text-center"><span className="badge bg-danger"><span
                    className="rioglyph rioglyph-remove-sign"/></span></div>;
            case 'INACTIVE':
                return <div className="text-danger text-center"><span className="badge bg-lighter">
                        <span className="rioglyph rioglyph-minus"/></span></div>;
            default:
                return <div className="text-danger text-center">n/a</div>;
        }
    };

    return (
        <div className="display-grid">
            <div className="text-color-darker text-size-20">
                <h3><FieldOrNoData field={type}/></h3>
            </div>
            <div className="display-flex align-items-center">
                <div>
                    {
                        renderOnlineIcon()
                    }
                </div>
                <div className="padding-left-5">
                    <span className=" text-bold text-size-18">{serialNumber}</span>
                </div>
            </div>
            {
                (device?.lastOnlineDateTime) && <div>
                    <span className=" text-color-darker text-size-12">
                        <FormattedMessage id={'intl-msg:coreconAdminWeb.lastMessage'}/>:
                            <span className=" padding-left-5">
                                <FieldOrNoData field={device.lastOnlineDateTime ?
                                    <FormattedTime value={device.lastOnlineDateTime}
                                                   year="numeric"
                                                   month="2-digit"
                                                   day="2-digit"/>
                                    : undefined}/>
                        </span>
                    </span>
                </div>
            }
        </div>
    );
};
