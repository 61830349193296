import {ColumnDescriptorProps} from '../../../../../components/table/ListTable';
import {ContinentalDeviceInfo, DeviceInfo, SREDeviceInfo} from './DeviceInformation';
import {Cm4MetadataImportRecordValidationError} from './uploadResult';

export const nasysUploadTableColumnDescriptors: ColumnDescriptorProps<DeviceInfo>[] = [
    {
        id: 'vin7',
        field: 'Vin7',
        label: 'VIN7',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'Vin17',
        field: 'Vin17',
        label: 'intl-msg:coreconAdminWeb.vin',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'created',
        field: 'created',
        label: 'intl-msg:coreconAdminWeb.upload.date',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'Imei',
        field: 'Imei',
        label: 'IMEI',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'Iccid',
        field: 'Iccid',
        label: 'ICCID',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    },
];

interface UploadEntryFieldProp {
    field: string;
}

const UploadEntryFieldDelimiters = ['\'', '"'];

const UploadEntryField = ({field}: UploadEntryFieldProp) => {
    let formatted = field;
    if (field === undefined) {
        formatted = 'N/A';
    } else if (UploadEntryFieldDelimiters.includes(field[0]) &&
        UploadEntryFieldDelimiters.includes(field[field.length - 1])) {
        formatted = field.substring(1, field.length - 1);
    }

    if (formatted === '') {
        formatted = 'N/A';
    }
    return <span>{formatted}</span>;
};

export const stoneridgeUploadTableColumnDescriptors: ColumnDescriptorProps<SREDeviceInfo>[] = [
    {
        id: 'ID',
        field: 'ID',
        label: 'intl-msg:coreconAdminWeb.upload.stoneridge.list.id',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'TBM3_serial_number',
        field: 'TBM3_serial_number',
        label: 'intl-msg:coreconAdminWeb.upload.tbm3Serial',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'VCM_serial_number',
        field: 'VCM_serial_number',
        label: 'intl-msg:coreconAdminWeb.upload.vcmSerial',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'zsb_date_assembly',
        field: 'zsb_date_assembly',
        label: 'intl-msg:coreconAdminWeb.upload.zsbDateAssembly',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'SIM_ID',
        field: 'SIM_ID',
        label: 'intl-msg:coreconAdminWeb.upload.stoneridge.list.sim_id',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'TBM3_IMEI',
        field: 'TBM3_IMEI',
        label: 'intl-msg:coreconAdminWeb.upload.stoneridge.list.tbm3_imei',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'IMSI',
        field: 'IMSI',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.imsi',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'ICCID',
        field: 'ICCID',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.iccid',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }
];

export const continentalUploadTableColumnDescriptors: ColumnDescriptorProps<ContinentalDeviceInfo>[] = [
    {
        id: 'iccid',
        field: 'iccid',
        label: 'ICCID',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'ecuid',
        field: 'ecuid',
        label: 'ECUID',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'imei',
        field: 'imei',
        label: 'IMEI',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'imsi',
        field: 'imsi',
        label: 'IMSI',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }, {
        id: 'sn',
        field: 'sn',
        label: 'intl-msg:coreconAdminWeb.hwSerial',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    },
];

export const cm4UploadErrorColumnDescriptors: ColumnDescriptorProps<Cm4MetadataImportRecordValidationError>[] = [
    {
        id: 'sn',
        field: 'sn',
        label: 'intl-msg:coreconAdminWeb.hwSerial',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    },
    {
        id: 'message',
        field: 'message',
        label: 'intl-msg:coreconAdminWeb.journal.message',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    },
    {
        id: 'record',
        field: 'record',
        label: 'intl-msg:coreconAdminWeb.journal.uploadLineNumber',
        className: 'min-width-150',
        format: (field) => <UploadEntryField field={field}/>,
    }
];