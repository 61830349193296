import {DEVICE_TYPE} from './device-state.types';
import Map from 'lodash/map';

export interface ApiDeviceSigningDataModelList {
    content: ApiDeviceSigningDataModelTBM3[];
}

export interface ApiDeviceSigningDataModelListCM4 {
    content: ApiDeviceSigningDataModelCM4[];
}

export interface SREData {
    id: string;
    iccid: string;
    tbm3_imei: string;
    imsi: string;
    sim_id: string;
    sp: string;
    state: string;
    primary_msisdn: string;
    customer_code: string;
    tariff_name: string;
    date_created: string;
    date_active_test_started: string;
    tbm3_man_part_number: string;
    tbm3_software_version: string;
    tbm3_hardware_version: string;
    tbm3_supplier_part_number: string;
    network_test_date: string;
    tbm3_device_type: string;
    network_test_result: string;
    vcm_imei: string;
    vcm_man_part_number: string;
    vcm_software_version: string;
    vcm_hardware_version: string;
    vcm_supplier_part_number: string;
    vcm_device_type: string;
    zsb_man_part_numberr: string;
    zsb_supplier_part_number: string;
    tbm3_serial_number: string;
    vcm_serial_number: string;
}

export interface CertData {
    serialNumber: string;
    iccid: string;
    imei: string;
    imsi: string;
}

export enum SigningStatus {
    SRE_DATA_UPLOADED = 'SRE_DATA_UPLOADED',
    FAILED = 'FAILED',
    PENDING = 'PENDING',
    SIGNED = 'SIGNED',
    FETCHED_BY_DEVICE = 'FETCHED_BY_DEVICE'
}

export enum SigningErrorStatus {
    NONE = 'NONE',
    INVALID_CAR = 'INVALID_CAR',
    INVALID_SERIALNUMBER = 'INVALID_SERIALNUMBER',
    CA_SERVICE_UNREACHABLE = 'CA_SERVICE_UNREACHABLE',
    CA_SERVICE_RESPONSE_INVALID = 'CA_SERVICE_RESPONSE_INVALID',
    DEVICE_SERVICE_UNREACHABLE = 'DEVICE_SERVICE_UNREACHABLE',
    DEVICE_SERVICE_RESPONSE_INVALID = 'DEVICE_SERVICE_RESPONSE_INVALID',
    DEVICE_SERVICE_VALIDATION_FAILED = 'DEVICE_SERVICE_VALIDATION_FAILED',
    DEVICE_SERVICE_SRE_DATA_MISSING = 'DEVICE_SERVICE_SRE_DATA_MISSING',
    DEVICE_SERVICE_SRE_DATA_VALIDATION_FAILED = 'DEVICE_SERVICE_SRE_DATA_VALIDATION_FAILED'
}

export interface SigningState {
    signingStatus: SigningStatus;
    errorStatus: SigningErrorStatus;
    errorMessage: string;
    timestamp: Date;
}

export interface ApiDeviceSigningDataModelCommon {
    id: string;
    deviceType: DEVICE_TYPE;
    serialNumber: string;
    imei: string;
    imsi: string;
    iccid: string;

    signingState: SigningState;

    firstSigningDate: Date;
    cntRejectedSign: number;
    cntFetchedSign: number;
}

export interface ApiDeviceSigningDataModelTBM3 extends ApiDeviceSigningDataModelCommon {
    sreData: SREData;
    certData: CertData;
}

export interface ApiDeviceSigningDataModelCM4 extends ApiDeviceSigningDataModelCommon {
    supplierData: boolean;
    iccid: string;
    imsi: string;
    key: string;
    initialKey: string;
    prod: boolean;
}

export interface DeviceSigningCertificateInfo {
    serialNumber: string;
    certSerialNumber: string;
    certusage: string;
    valid: boolean;
    expire: string;
    validFrom: string;
    pubkey: string;
    certificate: string;
}

export const mapDeviceSigningApiResponse =
    (response: ApiDeviceSigningDataModelList): ApiDeviceSigningDataModelTBM3[] => {
        if (response && response.content) {
            return response.content;
        }
        return [];
    };

export const mapDeviceSigningApiResponseCM4 =
    (response: ApiDeviceSigningDataModelListCM4): ApiDeviceSigningDataModelCM4[] => {
        if (response && response.content) {
            return Map(response.content, mapDeviceSigningDetailsApiResponseCM4);
        }
        return [];
    };

export const mapDeviceSigningDetailsApiResponseCM4 =
    (response: ApiDeviceSigningDataModelCM4): ApiDeviceSigningDataModelCM4 => {
        if (response) {
            response.deviceType = DEVICE_TYPE.CM4;
        }
        return response;
    };

export interface DeviceMonitor {
    imei: string;
    vin17: string;
    vin7: string;
    iccid: string;
    serialNumber: string;

    created: Date;
    /** Signing date */
    timestamp: Date;
    initSuccessful: boolean;
    initState: boolean;
    errorState: string;

    vehicleInit: boolean;
    fleetadminResgistrationTime: Date;
    replaced: boolean;
}

export interface NotificationInfo {
    serialNumber: string;
    hwVariant: string;
    baseSwVersion: string;
    vin: string;
    type: string;
    lastActivity: Date;
    replaced: boolean;
    wrongPartitionSize: boolean;
    latamDevice: boolean;
}

export interface AllDeviceSigningInfo {
    signing: ApiDeviceSigningDataModelCM4 | ApiDeviceSigningDataModelTBM3 | undefined;
    monitor: DeviceMonitor | undefined;
}

export const isLatamDevice = (iccid: string): boolean => iccid.startsWith('8955');