import React, {useState} from 'react';
import {ContinentalDeviceInfo, DeviceInfo, SREDeviceInfo} from './DeviceInformation';
import {ListTable} from '../../../../../components/table/ListTable';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import FilePicker from '@rio-cloud/rio-uikit/FilePicker';
import {
    cm4UploadErrorColumnDescriptors,
    continentalUploadTableColumnDescriptors,
    nasysUploadTableColumnDescriptors,
    stoneridgeUploadTableColumnDescriptors
} from './columnDescriptors';
import download from 'downloadjs';
import {useUploadContinentalStatesMutation} from '../../../../../services/journalContinentalStateApi';
import {useIntl} from 'react-intl';
import {useUploadStoneridgeMetadataMutation} from '../../../../../services/journalStoneridgeStateApi';
import {UploadResult} from './uploadResult';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import {renderErrorMessage} from '../journal/columnDescriptors';

enum DEVICE_TYPE {
    STONERIDGE = 'STONERIDGE',
    NASYS = 'NASYS',
    CONTINENTAL = 'CONTINENTAL',
    NOT_SELECTED = 'NOT_SELECTED'
}

interface UploadMessage {
    message: string;
    style: string;
    response?: UploadResult;
}

const Upload = () => {
    const {formatMessage} = useIntl();
    const [checkingEnabled, setCheckingEnabled] = useState(false);
    const [nasysDevices, setNasysDevices] = useState<DeviceInfo[]>([]);
    const [stoneridgeDevices, setStoneridgeDevices] = useState<SREDeviceInfo[]>([]);
    const [continentalDevices, setContinentalDevices] = useState<ContinentalDeviceInfo[]>([]);
    const [errorNasysDevices, setErrorNasysDevices] = useState<DeviceInfo[]>([]);
    const [errorStoneridgeDevices, setErrorStoneridgeDevices] = useState<SREDeviceInfo[]>([]);
    const [selectedDeviceType, setSelectedDeviceType] = useState(DEVICE_TYPE.NOT_SELECTED);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [message, setMessage] = useState<UploadMessage>({message: '', style: ''});
    const [errorReport, setErrorReport] = useState<UploadResult>({} as UploadResult);

    const [uploadContinentalStates, {isLoading}] = useUploadContinentalStatesMutation();
    const [uploadSREData] = useUploadStoneridgeMetadataMutation();
    const loading = isLoading;

    const handleCsvExport = (csvImported: string, filenameImported: string) => {
        download(csvImported, filenameImported, 'text/csv');
    };

    const handleCheckDevicesAndAddResultsToDb = () => {
        return;
    };

    const handleUploadCall = (promise: Promise<UploadResult>) => {
        promise
            .then((uploadResult) => setMessage({
                message: 'Upload successful',
                style: 'bs-callout-primary',
                response: uploadResult
            }))
            .catch((error) => {
                setMessage({
                    message: formatMessage({id: 'intl-msg:coreconAdminWeb.upload.errorNoUpload'}),
                    style: 'bs-callout-primary'
                });
                if (error.data) {
                    setErrorReport(error?.data);
                } else {
                    setErrorReport(error.error);
                }
            })
            .finally(() => {
                setStoneridgeDevices([]);
                setNasysDevices([]);
            });
    };

    const handleUploadSREDataToDb = () => {
        handleUploadCall(uploadSREData({devices: processCsvFromData(), filename: selectedFileName}).unwrap());
    };

    const handleUploadContinentalData = () => {
        handleUploadCall(uploadContinentalStates({devices: continentalDevices, filename: selectedFileName}).unwrap());
    };

    const processCsvFromData = (): string => {
        let csvImported = '';
        stoneridgeDevices.forEach((row: any) => {
            csvImported += row.join(';');
            csvImported += '\n';
        });
        return csvImported;
    };

    const csvExportImportLines = (event: any) => {
        event.preventDefault();
        const filenameImported = selectedFileName.replace('.csv', '-imported.csv');
        const csvImported = processCsvFromData();
        handleCsvExport(csvImported, filenameImported);
    };

    const csvExportFilteredLines = (event: any) => {
        event.preventDefault();
        const filename = selectedFileName.replace('.csv', '-filterd.csv');
        let csv = stoneridgeDevices[0].join(';') + '\n';
        stoneridgeDevices.forEach((row: any) => {
            csv += row.join(';');
            csv += '\n';
        });
        handleCsvExport(csv, filename);
    };

    const checkDeviceCertificateState = () => {
        setCheckingEnabled(false);
        setMessage({
            message: formatMessage({id: 'intl-msg:coreconAdminWeb.upload.uploadStarted'}),
            style: 'bs-callout-primary'
        });
        if (selectedDeviceType === DEVICE_TYPE.NASYS) {
            // handleCheckDevicesAndAddResultsToDb();
        } else if (selectedDeviceType === DEVICE_TYPE.STONERIDGE) {
            handleUploadSREDataToDb();
        } else if (selectedDeviceType === DEVICE_TYPE.CONTINENTAL) {
            if (continentalDevices.length > 0) {
                handleUploadContinentalData();
            } else {
                setContinentalDevices([]);
                setMessage({message: 'No data to upload', style: 'bs-callout-primary'});
            }
        }
    };

    const getAllTbm3sOutOfReadFile = (result_raw: string | ArrayBuffer | null) => {
        if (result_raw === null) {
            return;
        }
        const result: string = result_raw instanceof ArrayBuffer ?
            (new TextDecoder()).decode(result_raw)
            : result_raw;

        if (result.startsWith('[')) {
            getAllDevicesOutOfContinentalReadFile(result);
        } else {
            const tbmInformationLines = result.split(/\r|\n/);
            const tbmInformation = tbmInformationLines[0].split(';');

            if (tbmInformation.length === 5) {
                getAllTbm3sOutOfNasysReadFile(tbmInformationLines);
            } else if (tbmInformation.length === 30) {
                getAllTbm3sOutOfSREReadFile(tbmInformationLines);
            }
        }
    };

    const getAllDevicesOutOfContinentalReadFile = (deviceInformation: string) => {
        const devices = JSON.parse(deviceInformation);
        const informUserIfTBM3ParsingWorked = formatMessage(
            {id: 'intl-msg:coreconAdminWeb.upload.readCM4Data'},
            {
                numberOfLines: devices.length,
            });
        setContinentalDevices(devices);
        setCheckingEnabled(true);
        setSelectedDeviceType(DEVICE_TYPE.CONTINENTAL);
        setMessage({message: informUserIfTBM3ParsingWorked, style: 'bs-callout-primary'});
    };

    const getAllTbm3sOutOfNasysReadFile = (tbmInformationLines: string[]) => {
        let arrResult: DeviceInfo[] = [];
        const errResult = [];
        let allNewTbmsAreValid = true;
        let noOfLines = 0;
        let noOfRemovedLines = 0;
        let informUserIfTBM3ParsingWorked;
        for (const tbmInfoLine of tbmInformationLines) {
            if (tbmInfoLine !== '') {
                const tbmInformation = tbmInfoLine.split(';');
                noOfLines++;
                if (tbmInformation.length !== 5) {
                    allNewTbmsAreValid = false;
                    informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                    arrResult = [];
                    noOfRemovedLines++;
                    break;
                }
                if (tbmInformation[2].length > 12) {
                    const day = tbmInformation[4].substring(0, 2);
                    const month = tbmInformation[4].substring(3, 5);
                    const year = tbmInformation[4].substring(6, 10);
                    const standardDateFormat = `${year}-${month}-${day}`;
                    const creationTimeWithoutHours = new Date(standardDateFormat).toISOString();
                    tbmInformation[4] = creationTimeWithoutHours.substring(0, 23);
                    const newTBM = new DeviceInfo(tbmInformation);
                    arrResult.push(newTBM);
                } else {
                    noOfRemovedLines++;
                    const newTBM = new DeviceInfo(tbmInformation);
                    errResult.push(newTBM);
                }
            }
        }
        for (const newTbm of arrResult) {
            if (!newTbm.isValid()) {
                allNewTbmsAreValid = false;
                informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                arrResult = [];
                break;
            }
        }
        informUserIfTBM3ParsingWorked = formatMessage(
            {id: 'intl-msg:coreconAdminWeb.upload.readNasysData'},
            {
                validLines: arrResult.length,
                numberOfLines: noOfLines,
                numberOfRemovedLines: noOfRemovedLines,
            });
        setNasysDevices(arrResult);
        setErrorNasysDevices(errResult);
        setCheckingEnabled(false);
        setSelectedDeviceType(DEVICE_TYPE.NASYS);
        setMessage({message: informUserIfTBM3ParsingWorked, style: 'bs-callout-primary'});
    };

    const getAllTbm3sOutOfSREReadFile = (tbmInformationLines: string[]) => {
        let arrResult: SREDeviceInfo[] = [];
        const errResult = [];
        let allNewTbmsAreValid = true;
        let noOfLines = 0;
        let noOfRemovedLines = 0;
        let informUserIfTBM3ParsingWorked;
        for (const tbmInfoLine of tbmInformationLines) {
            if (tbmInfoLine === '') {
                continue;
            }

            const tbmInformation = tbmInfoLine.split(';');
            noOfLines++;
            if (tbmInformation.length !== 30) {
                allNewTbmsAreValid = false;
                informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                arrResult = [];
                noOfRemovedLines++;
                break;
            }
            if (noOfLines === 1 || verifyLine(tbmInformation)) {
                const newTBM: SREDeviceInfo = new SREDeviceInfo(tbmInformation);
                arrResult.push(newTBM);
            } else {
                noOfRemovedLines++;
                const newTBM: SREDeviceInfo = new SREDeviceInfo(tbmInformation);
                errResult.push(newTBM);
            }
        }
        for (const newTbm of arrResult) {
            if (!newTbm.isValid()) {
                allNewTbmsAreValid = false;
                informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                arrResult = [];
                break;
            }
        }
        informUserIfTBM3ParsingWorked = formatMessage(
            {id: 'intl-msg:coreconAdminWeb.upload.readStoneridgeData'},
            {
                validLines: arrResult.length - 1,
                numberOfLines: noOfLines,
                numberOfRemovedLines: noOfRemovedLines,
            });
        setStoneridgeDevices(arrResult);
        setErrorStoneridgeDevices(errResult);
        setCheckingEnabled(true);
        setSelectedDeviceType(DEVICE_TYPE.STONERIDGE);
        setMessage({message: informUserIfTBM3ParsingWorked, style: 'bs-callout-primary'});
    };

    const verifyLine = (tbmInformation: string[]) => {
        const tbm3SerialNumber = tbmInformation[3].replace(/^"(.*)"$/, '$1');
        const vcmSerialNumber = tbmInformation[9].replace(/^"(.*)"$/, '$1');
        return tbm3SerialNumber.length === 10 || vcmSerialNumber.length === 10;
    };

    const readFile = (acceptedFile: FileList | null) => {
        const testResultText = `Not checked yet...\n${message.message}`;
        setErrorReport({message: ''} as UploadResult);
        if (!acceptedFile) {
            setMessage({message: testResultText, style: 'bs-callout-primary'});
            return;
        }
        setSelectedFileName(acceptedFile[0].name);
        const reader = new FileReader();
        const nasysExtract = acceptedFile[0];

        reader.readAsText(nasysExtract);
        reader.onloadend = () => {
            getAllTbm3sOutOfReadFile(reader.result);
        };
    };

    const renderStoneridgeUploadTable = () => {
        return (
            stoneridgeDevices.length > 0 &&
            (<div>
                <div>
                    <div className={'padding-bottom-10'}>
                        <ExpanderPanel
                            title={
                                <div>
                                    <b className={'padding-right-10'}>Valid Devices
                                        - {stoneridgeDevices.length - 1}</b>
                                    <Button onClick={csvExportImportLines} disabled={loading} bsStyle={Button.PRIMARY}>
                                        <span>Export</span>
                                    </Button>
                                </div>
                            }
                            bsStyle={'default'}
                            bodyClassName={'padding-0'}
                            open
                        >
                            <ListTable items={stoneridgeDevices.slice(1)}
                                       itemKey={'ID'}
                                       isLoading={loading}
                                       showHeader
                                       columnDescriptors={stoneridgeUploadTableColumnDescriptors}/>
                        </ExpanderPanel>
                    </div>
                </div>
                <br/>
                <div>
                    <h4>{formatMessage({id: 'intl-msg:coreconAdminWeb.upload.invalidLines'})}</h4>
                    <ExpanderPanel
                        title={
                            <div>
                                <b className={'padding-right-10'}>Errors - {errorStoneridgeDevices.length}</b>
                                <Button className="btn btn-primary" onClick={csvExportFilteredLines}
                                        disabled={loading}>
                                    <span>Export</span>
                                </Button>
                            </div>
                        }
                        bsStyle={'default'}
                        titleClassName={'text-bold'}
                        bodyClassName={'padding-0'}
                        open
                    >
                        <ListTable items={errorStoneridgeDevices}
                                   itemKey={'ID'}
                                   isLoading={loading}
                                   className="text-color-danger"
                                   showHeader
                                   columnDescriptors={stoneridgeUploadTableColumnDescriptors}/>
                    </ExpanderPanel>
                </div>
            </div>)
        );
    };

    const renderContinentalUploadTable = () => {
        return (
            <div>
                <div>
                    <ExpanderPanel
                        title={
                            <div>
                                <b className={'padding-right-10'}>Devices</b>
                            </div>
                        }
                        bsStyle={'default'}
                        titleClassName={'text-bold'}
                        bodyClassName={'padding-0'}
                        open
                    >
                        <ListTable items={continentalDevices}
                                   itemKey={'iccid'}
                                   showHeader
                                   columnDescriptors={continentalUploadTableColumnDescriptors}/>
                    </ExpanderPanel>
                </div>
            </div>
        );
    };

    const renderNasysUploadTable = () => {
        return (
            <div>
                <div>
                    <ListTable items={nasysDevices}
                               itemKey={'Vin7'}
                               showHeader
                               columnDescriptors={nasysUploadTableColumnDescriptors}/>
                </div>
                <br/>
                <div>
                    <h4>{'Invalid lines (Ignored)'}</h4>
                    <ListTable items={errorNasysDevices}
                               itemKey={'Vin7'}
                               className="text-color-danger"
                               showHeader
                               columnDescriptors={nasysUploadTableColumnDescriptors}/>
                </div>
            </div>
        );
    };

    let uploadButtonText;
    let renderedTable;
    switch (selectedDeviceType) {
        case DEVICE_TYPE.STONERIDGE:
            renderedTable = renderStoneridgeUploadTable();
            uploadButtonText = formatMessage({id: 'intl-msg:coreconAdminWeb.upload.uploadStoneridgeData'});
            break;
        case DEVICE_TYPE.NASYS:
            renderedTable = renderNasysUploadTable();
            uploadButtonText = formatMessage({id: 'intl-msg:coreconAdminWeb.upload.uploadNasysData'});
            break;
        case DEVICE_TYPE.CONTINENTAL:
            renderedTable = renderContinentalUploadTable();
            uploadButtonText = formatMessage({id: 'intl-msg:coreconAdminWeb.upload.uploadCM4Data'});
            break;
        default:
            renderedTable = null;
            uploadButtonText = formatMessage({id: 'intl-msg:coreconAdminWeb.upload.upload'});
            break;
    }

    let renderedMessage;
    if (errorReport.message?.includes('\n')) {
        const [title, errors] = renderErrorMessage(errorReport.message);
        renderedMessage = (
            <div>
                <div className={'lead'}>
                    {title}
                </div>
                {errors}
            </div>);
    } else if (errorReport?.message) {
        renderedMessage = <p>{errorReport.message}</p>;
    }

    return (
        <div className="container-fluid user-select-none">
            <div className="bg-white rounded shadow-smooth">
                <h5 className="padding-left-10 padding-top-10">
                    {formatMessage({id: 'intl-msg:coreconAdminWeb.upload.uploadIntroText'})}
                </h5>
                <div className="display-flex">
                    <div className="flex-basis-5pct padding-10 rounded">
                        <FilePicker
                            className="btn-primary"
                            multiple={false}
                            accept={{
                                'text/csv': ['.csv'],
                                'application/json': ['.json']
                            }}
                            label={formatMessage({id: 'intl-msg:coreconAdminWeb.upload.selectFile'})}
                            onPick={(acceptedFiles: FileList | null) => readFile(acceptedFiles)}/>
                    </div>
                    <div className="flex-basis-5pct padding-10 rounded">
                        <Button className="btn btn-primary"
                                onClick={checkDeviceCertificateState}
                                disabled={!checkingEnabled}>
                            {uploadButtonText}
                        </Button>
                    </div>
                    <div className="flex-basis-30pct padding-left-2 padding-top-5 rounded">
                        <p>{selectedFileName}</p>
                    </div>
                </div>
            </div>
            <div className="row margin-top-20">
                <div className="col-md-12">
                    {
                        loading &&
                        <div className="padding-20">
                            <ContentLoader height={50}/>
                        </div>
                    }
                    <div>
                        {message.message && <div className={`bs-callout ${message.style}`}>
                            <h4>
                                {message.message}
                            </h4>
                            {
                                message.response &&
                                <ExpanderPanel title={'Upload details'} bsStyle={'success'}>
                                    <p><b>Operation: </b>{message.response.operation}</p>
                                    <p><b>Processed count: </b>{message.response.processedCount}</p>
                                    <p><b>Message: </b>{message.response.message}</p>
                                </ExpanderPanel>
                            }
                            {
                                (renderedMessage || errorReport.errors) &&
                                <ExpanderPanel title={'Error details'} bsStyle="danger" open>
                                    {renderedMessage}
                                    {errorReport.errors &&
                                        <ListTable itemKey={'sn'}
                                                   items={errorReport.errors}
                                                   columnDescriptors={cm4UploadErrorColumnDescriptors}
                                                   showHeader/>
                                    }
                                </ExpanderPanel>
                            }
                        </div>
                        }
                    </div>
                    <br/>
                </div>
            </div>
            {selectedFileName && renderedTable}
        </div>
    );
};

export default Upload;
