import React from 'react';
import {FormattedMessage} from 'react-intl';

// display-flex
const classes = 'text-color-lighter text-size-12 text-uppercase white-space-nowrap align-items-center';

export const NoData = () => {
    return (
        <span className={classes}>
            <FormattedMessage id="intl-msg:coreconAdminWeb.noData"/>
        </span>
    );
};

export const YesOrNo = ({field}: any) => {
    const yesOrNoClasses = field ? 'rioglyph-ok text-success' : 'rioglyph-remove text-danger';
    return (
        <span className={`rioglyph text-size-large align-middle ${yesOrNoClasses}`}/>
    );
};

export interface FieldProps {
    field: any;
}

export const FieldOrNoData = ({field}: any) => {
    if (field || field === 0) {
        return field;
    }
    return <NoData/>;
};

export const RegistrationMileageCheckFlagOrNoData = ({field}: any) => {
    if (field === null || field === undefined) {
        return <NoData/>;
    }
    return field ? (<span>Yes</span>) : (<span>No</span>);
};
