import {DEVICE_TYPE} from '../device-sidebar/device-state.types';

export enum SIGNING_STATE {
    'REJECTED' = 'rejected',
    'RECURRENT' = 'recurrent',
    'SUCCESSFUL' = 'successful',
}

/* export enum DEVICE_TYPE {
    'TBM3' = 'TBM3',
    'VCM' = 'VCM',
    'CM4' = 'CM4',
}*/

export const signingStateFilterOptions = [
    {
        id: SIGNING_STATE.REJECTED,
        label: 'intl-msg:coreconAdminWeb.deviceSigning.rejected',
        selected: true
    },
    {
        id: SIGNING_STATE.RECURRENT,
        label: 'intl-msg:coreconAdminWeb.deviceSigning.recurrent',
        selected: false
    },
    {
        id: SIGNING_STATE.SUCCESSFUL,
        label: 'intl-msg:coreconAdminWeb.deviceSigning.successful',
        selected: false
    },
];

export const deviceTypeFilterOptions = [
    {
        id: DEVICE_TYPE.TBM3,
        label: 'intl-msg:coreconAdminWeb.deviceSigning.tbm3',
        selected: true
    },
    {
        id: DEVICE_TYPE.VCM,
        label: 'intl-msg:coreconAdminWeb.deviceSigning.vcm',
        selected: false
    },
    {
        id: DEVICE_TYPE.CM4,
        label: 'intl-msg:coreconAdminWeb.deviceSigning.cm4',
        selected: false
    },
];

export interface SigningFilter {
    signingState: SIGNING_STATE;
    deviceType: DEVICE_TYPE;
    startDateTime: number;
    endDateTime: number;
};

export interface SigningPage {
    page: number;
    size: number;
};

export class DeviceSigning {
    serialNumber: string = '';
    deviceType: DEVICE_TYPE | undefined;
}
