import React, {useEffect, useState} from 'react';
import BarChart from '@rio-cloud/rio-uikit/BarChart';
import Bar from '@rio-cloud/rio-uikit/Bar';
import {
    useFetchQuarterFourStatesQuery,
    useFetchQuarterOneStatesQuery, useFetchQuarterThreeStatesQuery, useFetchQuarterTwoStatesQuery,
} from '../../../../../../services/overviewStateApi';
import moment from 'moment/moment';
import size from 'lodash/fp/size';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

const getQuarterTimeRanges = (year: number) => {
    return {
        Q1: {
            start: moment(year, 'YYYY').quarter(1).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(1).endOf('quarter').unix() * 1000
        },
        Q2: {
            start: moment(year, 'YYYY').quarter(2).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(2).endOf('quarter').unix() * 1000
        },
        Q3: {
            start: moment(year, 'YYYY').quarter(3).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(3).endOf('quarter').unix() * 1000
        },
        Q4: {
            start: moment(year, 'YYYY').quarter(4).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(4).endOf('quarter').unix() * 1000
        },
    };
};

export const BackendDevicesGraph = () => {
    const [year, setYear] = useState(2023);
    const [data, setData] = useState<any[]>();
    const quarterTimeRanges = getQuarterTimeRanges(year);
    const {data: amountQuarterOne, isFetching: isFetchingQuarterOne} = useFetchQuarterOneStatesQuery(
        {
            start: quarterTimeRanges.Q1.start,
            end: quarterTimeRanges.Q1.end
        },
    );
    const {data: amountQuarterTwo, isFetching: isFetchingQuarterTwo} = useFetchQuarterTwoStatesQuery(
        {
            start: quarterTimeRanges.Q2.start,
            end: quarterTimeRanges.Q2.end
        },
    );
    const {data: amountQuarterThree, isFetching: isFetchingQuarterThree} = useFetchQuarterThreeStatesQuery(
        {
            start: quarterTimeRanges.Q3.start,
            end: quarterTimeRanges.Q3.end
        },
    );
    const {data: amountQuarterFour, isFetching: isFetchingQuarterFour} = useFetchQuarterFourStatesQuery(
        {
            start: quarterTimeRanges.Q4.start,
            end: quarterTimeRanges.Q4.end
        },
    );

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const data: any[] = [];
        if (amountQuarterOne !== undefined) {
            data.push({
                quarter: 'Q1',
                amount: amountQuarterOne
            });
        }
        if (amountQuarterTwo !== undefined) {
            data.push({
                quarter: 'Q2',
                amount: amountQuarterTwo
            });
        }
        if (amountQuarterThree !== undefined) {
            data.push({
                quarter: 'Q3',
                amount: amountQuarterThree
            });
        }
        if (amountQuarterFour !== undefined) {
            data.push({
                quarter: 'Q4',
                amount: amountQuarterFour
            });
        }
        setData(data);
    }, [amountQuarterOne, amountQuarterTwo, amountQuarterThree, amountQuarterFour]);

    return (
        <>
            {
                (size(data) < 4)
                || (isFetchingQuarterOne || isFetchingQuarterTwo || isFetchingQuarterThree || isFetchingQuarterFour) ?
                    <>
                        <Spinner isDoubleSized text="Loading"/>
                    </>
                    :
                    <>
                        <div className="display-flex justify-content-around margin-bottom-20">
                            <div className="col-md-6">
                                <a onClick={() => setYear(2020)}>
                                    <div className={year === 2020 ? 'text-bold text-decoration-underline' : ''}>2020
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a onClick={() => setYear(2021)}>
                                    <div className={year === 2021 ? 'text-bold text-decoration-underline' : ''}>2021
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a onClick={() => setYear(2022)}>
                                    <div className={year === 2022 ? 'text-bold text-decoration-underline' : ''}>2022
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a onClick={() => setYear(2023)}>
                                    <div className={year === 2023 ? 'text-bold text-decoration-underline' : ''}>2023
                                    </div>
                                </a>
                            </div>
                        </div>
                        <BarChart
                            data={data}
                            dataKey="quarter"
                            margin={{top: 25, bottom: 25}}
                            tooltip={false}
                            bars={[
                                <Bar
                                    key="bar"
                                    dataKey="amount"
                                    label={{
                                        position: 'top',
                                        formatter: (value: any) => `${value}`,
                                    }}
                                />,
                            ]}
                        />
                    </>
            }
        </>
    );
};
