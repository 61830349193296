import { ReactNode } from 'react';
import { getIdToken } from '../configuration/tokenHandling/tokenSlice';
import { store } from '../configuration/setup/store';

interface AuthorisationWrapperArgs {
    allowedRoles: string[];
    children: ReactNode;
}

const AuthorisationWrapper = ({allowedRoles, children}: AuthorisationWrapperArgs) => {
    const userRoles: string[] = getIdToken(store.getState())!['roles'];

    return (
        !!userRoles && userRoles.some((role: string) => allowedRoles.includes(role)) ?
            <>
                {children}
            </>
            :
            null
    );
};

export default AuthorisationWrapper;