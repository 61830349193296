import React from 'react';
import {FieldOrNoData, YesOrNo} from '../../../../../components/PropertyTableFieldDefinitions';
import {ColumnDescriptorProps} from '../../../../../components/table/ListTable';
import {DeviceSigningCertificateInfo} from '../device-signing.types';

export const certificatesColumnDescriptors: ColumnDescriptorProps<DeviceSigningCertificateInfo>[] = [{
    id: 'expire',
    field: 'expire',
    label: 'intl-msg:coreconAdminWeb.deviceSigning.expire',
    className: 'text-center width-50',
    format: (field: any, item: any) => (
        <span className="white-space-nowrap">
            <span className="margin-right-5">
                <FieldOrNoData field={field}/>
            </span>
        </span>
    ),
}, {
    id: 'validFrom',
    field: 'validFrom',
    label: 'intl-msg:coreconAdminWeb.deviceSigning.validFrom',
    className: 'text-center width-50',
    format: (field: any, item: any) => (
        <span className="white-space-nowrap">
            <span className="margin-right-5">
                <FieldOrNoData field={field}/>
            </span>
        </span>
    ),
}, {
    id: 'certusage',
    field: 'certusage',
    label: 'intl-msg:coreconAdminWeb.deviceSigning.certUsage',
    className: 'text-center width-50',
    format: (field: string, item: any) => (
        <span className="white-space-nowrap">
            <span className="margin-right-5">
                <FieldOrNoData field={field?.toUpperCase()}/>
            </span>
        </span>
    ),
}, {
    id: 'valid',
    field: 'valid',
    label: 'intl-msg:coreconAdminWeb.deviceSigning.valid',
    className: 'text-center width-50',
    format: (field: any, item: any) => (
        <span className="white-space-nowrap">
            <span className="margin-right-5">
                <YesOrNo field={field}/>
            </span>
        </span>
    ),
}];
