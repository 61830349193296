import {FormattedMessage} from 'react-intl';
import {Link, NavLink} from 'react-router-dom';

import {DefaultUserMenu} from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';

import {config} from '../../../config';
import ServiceInfo from './ServiceInfo';
import {AppHeaderProps} from './AppHeader.types';
import {ROUTE_VEHICLE_DATA} from '../context/mbb-admin-web/mbbAdminWeb.routes';
import {ROUTE_HOME_DEVICE_STATE} from '../context/device-state/deviceState.routes';
import {ROUTE_HOME_UPLOAD} from '../context/upload/upload.routes';
import {SYSTEM_STATE} from '../context/system-state/systemState.routes';
import {ROUTE_HOME_CERTIFICATES} from '../context/certificates/certificates.routes';
import {ROUTE_HOME_OVERVIEW} from '../context/overview/overview.routes';
import {ROUTE_HOME_SIGNING} from '../context/device-signing/signing.routes';
import {ThemeToggler} from './ThemeToggler';

const navItems = [
    {
        key: 'overview',
        route: (
            <NavLink to={ROUTE_HOME_OVERVIEW}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.overview'}/>
            </NavLink>
        ),
    },
    {
        key: 'deviceState',
        route: (
            <NavLink to={ROUTE_HOME_DEVICE_STATE}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.deviceState.moduleName'}/>
            </NavLink>
        ),
    },
    {
        key: 'systemState',
        route: (
            <NavLink to={SYSTEM_STATE} style={{display: 'none'}}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.systemState.moduleName'}/>
            </NavLink>
        ),
    },
    {
        key: 'journal',
        route: (
            <NavLink to={ROUTE_HOME_UPLOAD}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.upload.moduleName'}/>
            </NavLink>
        ),
    },
    {
        key: 'mbbAdminWeb',
        route: (
            <NavLink to={ROUTE_VEHICLE_DATA} style={{display: 'none'}}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.mbbAdminWeb.moduleName'}/>
            </NavLink>
        ),
    },
    {
        key: 'certificates',
        route: (
            <NavLink to={ROUTE_HOME_CERTIFICATES}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.serviceCertificates.moduleName'}/>
            </NavLink>
        ),
    },
    {
        key: 'signing',
        route: (
            <NavLink to={ROUTE_HOME_SIGNING}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.deviceSigning.moduleName'}/>
            </NavLink>
        ),
    },
];

export const environment = process.env.NODE_ENV === 'production' ? 'production' : 'local';

export const serviceInfoItem = <ServiceInfo/>;
export const userMenuItem = <DefaultUserMenu environment={environment}/>;

const defaultAppHeader = () =>
    (<ApplicationHeader
        label={<FormattedMessage id={'intl-msg:starterTemplate.moduleName'}/>}
        homeRoute={<Link to={config.homeRoute || ''}/>}
        navItems={navItems}
        actionBarItems={[ThemeToggler()]}
        // actionBarItems={[serviceInfoItem, userMenuItem]}
    />);

const AppHeader = (props: AppHeaderProps) => {
    const activeActiveAppHeader = defaultAppHeader();
    return activeActiveAppHeader;
};

export default AppHeader;
