import React from 'react';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';

interface ValueWithHintProperties {
    value: string;
    valueColor?: string;
    valueSize?: string;
    hint: string;
    hintColor?: string;
    hintSize?: string;
    unit: string;
    unitColor?: string;
    unitSize?: string;
    isLoading?: boolean;
}

export const ValueWithHint = ({
                                  value = '',
                                  valueColor = '',
                                  valueSize = 'text-size-h2',
                                  unit = '',
                                  unitColor = '',
                                  unitSize = 'text-size-h4',
                                  hint = '',
                                  hintColor = '',
                                  hintSize = 'text-size-12',
                                  isLoading = false,
                              }: ValueWithHintProperties) => {
    return isLoading ? <ContentLoader/>
        : <div className={'text-bold'}>
            <div>
                <span className={`${valueColor} ${valueSize}`}>{value}</span>
                <span className={`${unitColor} ${unitSize}`}>{unit}</span>
            </div>
            <div className={`${hintColor} ${hintSize}`}>{hint}</div>
        </div>;
};
