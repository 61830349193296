import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configuration/setup/store';
import { EHeaderMenuKeys } from './AppHeader.types';

export interface HeaderState {
    selectedMenu: EHeaderMenuKeys;
}

const initialState: HeaderState = {
    selectedMenu: EHeaderMenuKeys.NONE
};

const headerSlice = createSlice({
    name: 'appHeader',
    initialState,
    reducers: {
        selectedMenuStored: (state, action: PayloadAction<EHeaderMenuKeys>) => {
            state.selectedMenu = action.payload;
        }
    },
});

export const { selectedMenuStored } = headerSlice.actions;

export const getSelectedMenu = (state: RootState) => state.appHeader.selectedMenu;

export default headerSlice.reducer;