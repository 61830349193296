export enum SERVICE_CERTIFICATE_TYPE {
    DEVELOPMENT = 'DEVELOPMENT',
    PRODUCTION = 'PRODUCTION',
}

export interface ServiceCertificate {
    host: string;
    service: string;
    remainingDays: number;
    expirationDate: Date;
    createdBy: string;
    prod: boolean;
}

export class ServiceCertificateImpl implements ServiceCertificate {
    host: string;
    service: string;
    remainingDays: number;
    expirationDate: Date;
    createdBy: string;
    prod: boolean;

    constructor(json: any) {
        this.host = json['host'];
        this.service = json['service'];
        this.remainingDays = json['remainingDays'];
        this.expirationDate = new Date(json['expirationDate']);
        this.createdBy = json['createdBy'];
        this.prod = json['prod'];
    }
}
