export enum DEVICE_TYPE {
    'TBM3' = 'TBM3',
    'VCM' = 'VCM',
    'OCU3' = 'OCU3',
    'CM4' = 'CM4',
}

export enum Status {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    INACTIVE = 'INACTIVE',
    EMPTY = ''
}

export interface ApiDeviceStateTBM3 {
    box_serial_number: string;
    builtin_position: {
        box_serial_number: string;
        builtin_date_time: string;
        latitude: number;
        longitude: number;
        position_date_time: string;
    };
    gateway: string;
    hardware_region: string;
    hardware_variant: string;
    hardware_version: string;
    last_mileage: number;
    last_mileage_date_time: string;
    last_online_date_time: number;
    originator: string;
    previous_vin: string;
    software_version: string;
    status: Status;
    tco_info: {
        supplier: string;
        variant: string;
        hardware_number: string;
        software_number: string;
    };
    vehicle_type: string;
    vehicle_variant: string;
    vin: string;
    mileage_check_skipped: boolean;
    account_id: string;
}

export interface ApiDeviceStateTBM3List {
    paging: {
        cursor_next: '';
        limit: '';
    };
    device_states: ApiDeviceStateTBM3[];
}

export interface ApiDeviceStateMBB {
    deviceId: string;
    mbbRegistryRegistrationStatus: string;
    mfhDeviceRegistrationStatus: string;
    mvsGeoStatus: string;
    tssSubscriptionStatus: string;
    vin: string;
    lastOnlineDateTime: number;
    status: Status;
    mileageCheckSkipped: boolean;
    accountId: string;
}

export interface ApiDeviceStateMBBList {
    paging: {
        cursor_next: '';
        limit: '';
    };
    device_states: ApiDeviceStateMBB[];
}

export interface DeviceStateCommon {
    id?: string;
    status?: Status;
    serialNumber: string;
    originator: string;
    type: DEVICE_TYPE;
    vin?: string;
    lastOnlineDateTime?: number;
    lastMileage?: number;
    softwareVersion?: string;
    vehicleVariant?: string;
    vehicleType?: string;
    hardwareVersion?: string;
    hardwareVariant?: string;
    hardwareRegion?: string;
    lastMileageDateTime?: string;
    builtinPosition?: {
        serialNumber: string;
        builtinDateTime: string;
        latitude: number;
        longitude: number;
        positionDateTime: string;
    };
    gateway?: string;
    tcoInfo?: {
        supplier: string;
        variant: string;
        hardwareNumber: string;
        softwareNumber: string;
    };
    errorState?: string;
    accountId?: string;
    previousVin?: string;
}

export interface DeviceStateTBM3 extends DeviceStateCommon {
    gateway: string | undefined;
    builtinPosition?: {
        serialNumber: string;
        builtinDateTime: string;
        latitude: number;
        longitude: number;
        positionDateTime: string;
    };
    hardwareRegion: string;
    hardwareVariant: string;
    hardwareVersion: string;
    lastMileage: number;
    lastMileageDateTime: string;
    lastOnlineDateTime: number;
    previousVin: string;
    softwareVersion: string;
    tcoInfo?: {
        supplier: string;
        variant: string;
        hardwareNumber: string;
        softwareNumber: string;
    };
    vehicleType: string;
    vehicleVariant: string;
    mileageCheckSkipped: boolean;
}

export interface DeviceStateTBM3List {
    items: DeviceStateTBM3[];
    cursorNext: string;
}

export interface DeviceStateMBB extends DeviceStateCommon {
    type: DEVICE_TYPE;
    mbbRegistryRegistrationStatus: string;
    mfhDeviceRegistrationStatus: string;
    mvsGeoStatus: string;
    tssSubscriptionStatus: string;
    lastOnlineDateTime: number;
    errorState: string;
    mileageCheckSkipped: boolean;
    originator: string;
}

export interface DeviceStateMBBList {
    items: DeviceStateMBB[];
    cursorNext: string;
}

export interface MbbExternalError {
    error: {
        errorCode: string;
        description: string;
    };
}
