import {DeviceList} from './components/DeviceList';
import {FormattedMessage} from 'react-intl';

export const DeviceStateHome = () => {
    return (
        <>
            <h1 key="header"><FormattedMessage id={'intl-msg:coreconAdminWeb.deviceState.moduleName'} /></h1>
            <DeviceList key="deviceList"/>
        </>
    );
};
