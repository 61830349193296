import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import {config} from '../config';
import {accessToken} from '../configuration/tokenHandling/accessToken';
import {AccessToken} from '../configuration/tokenHandling/tokenSlice';
import {
    ApiSystemStateList,
    SystemStateFilter,
    SystemStatePage
} from '../features/app/context/system-state/system-state.types';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

export const systemStateApi = createApi({
    reducerPath: 'systemStates',
    tagTypes: ['SystemState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.SYSTEM_STATE_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => {
        return ({
            fetchSystemStates: builder.query<ApiSystemStateList, {
                filter: SystemStateFilter; page: SystemStatePage;
            }>({
                query: ({filter, page}) => ({
                    url: `/devices?${qs.stringify(
                        {
                            starttimespan: filter.startDateTime,
                            endtimespan: filter.endDateTime,
                            filter: filter.messageTypes,
                            page: page.page,
                            size: page.size,
                        },
                        {
                            skipNulls: true,
                            encode: false,
                        }
                    )}`,
                    responseHandler: async (response: Response) => {
                        const data = await response.json();
                        return data;
                    },
                }),
                keepUnusedDataFor: 30,
                providesTags: ['SystemState'],
            }),
            fetchEventsForDevice: builder.query<ApiSystemStateList, {
                filter: SystemStateFilter; page: SystemStatePage;
            }>({
                query: ({filter, page}) => ({
                    url: `/devices/${filter.serialNumber}/events?${qs.stringify(
                        {
                            starttimespan: filter.startDateTime,
                            endtimespan: filter.endDateTime,
                            filter: filter.messageTypes,
                            page: page.page,
                            size: page.size,
                        },
                        {
                            skipNulls: true,
                            encode: false,
                        }
                    )}`,
                    responseHandler: async (response: Response) => {
                        const data = await response.json();
                        return data;
                    },
                }),
                keepUnusedDataFor: 30,
                providesTags: ['SystemState'],
            }),
        });
    },
});

// Export hooks for usage in function components which are
export const {useFetchSystemStatesQuery, useFetchEventsForDeviceQuery} = systemStateApi;
