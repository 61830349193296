import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../../../configuration/setup/store';
import {SigningSearchResult} from './components/SigningSearch';
import {DEVICE_TYPE, DeviceStateCommon} from '../device-sidebar/device-state.types';


interface SidebarState {
    devices: SigningSearchResult[];
}

const initialState = {
    devices: []
} as SidebarState;

export const KEY_SEPARATOR = '|';

export const genDeviceKey =
    (device?: DeviceStateCommon | undefined, type?: DEVICE_TYPE, serialNumber?: string): string => {
        if (device === undefined && type === undefined && serialNumber !== undefined) {
            return serialNumber;
        }
        if (device === undefined) {
            if (type === undefined || serialNumber === undefined) {
                return '';
            } else {
                return type + KEY_SEPARATOR + serialNumber;
            }
        } else {
            return device.type + KEY_SEPARATOR + device.serialNumber;
        }
    };

export const signingSlice = createSlice({
    name: 'signingSlice',
    initialState,
    reducers: {
        clearDevices: (state) => {
            state.devices = [];
        },
        saveDeviceInfo: (state, action: PayloadAction<SigningSearchResult | undefined>) => {
            if (action === undefined || action.payload === undefined) {
                return;
            }
            let valueSet;
            if (action.payload.monitor !== undefined) {
                valueSet = false;
                state.devices.map((value) => {
                    if (value.serialNumber === action.payload?.serialNumber
                        && value.signing?.deviceType !== DEVICE_TYPE.CM4) {
                        value.monitor = action.payload?.monitor;
                        valueSet = true;
                    }
                });
                if (!valueSet) {
                    state.devices.push(action.payload);
                }
            }
            if (action.payload.signing !== undefined) {
                valueSet = false;
                state.devices.map((value) => {
                    if (value.serialNumber === action.payload?.serialNumber) {
                        if (value.monitor !== undefined && action.payload?.signing?.deviceType !== DEVICE_TYPE.CM4) {
                            value.signing = action.payload?.signing;
                        }
                        valueSet = true;
                    }
                });
                if (!valueSet) {
                    state.devices.push(action.payload);
                }
            }
        }
    },
});

export const {
    saveDeviceInfo,
    clearDevices
} = signingSlice.actions;

export const getSearchResults = (state: RootState) => state.signing.devices;

export default signingSlice.reducer;