import {FormattedMessage, useIntl} from 'react-intl';
import DataTab from '@rio-cloud/rio-uikit/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/DataTabs';
import React, {useState} from 'react';
import {CertificateList} from './components/CertificateList';
import {SERVICE_CERTIFICATE_TYPE} from './certificates.types';

export const CertificatesHome = () => {
    const {formatMessage} = useIntl();
    const [tabKey, setTabKey] = useState(SERVICE_CERTIFICATE_TYPE.PRODUCTION);

    return (
        <>
            <h1><FormattedMessage id={'intl-msg:coreconAdminWeb.serviceCertificates.moduleName'}/></h1>
            <DataTabs activeKey={tabKey} onSelectTab={(newTabKey: SERVICE_CERTIFICATE_TYPE) => setTabKey(newTabKey)}>
                <DataTab
                    tabKey={SERVICE_CERTIFICATE_TYPE.PRODUCTION}
                    title={formatMessage({id: 'intl-msg:coreconAdminWeb.serviceCertificates.production'})}>
                    <CertificateList tabKey={tabKey}/>
                </DataTab>
                <DataTab
                    tabKey={SERVICE_CERTIFICATE_TYPE.DEVELOPMENT}
                    title={formatMessage({id: 'intl-msg:coreconAdminWeb.serviceCertificates.development'})}>
                    <CertificateList tabKey={tabKey}/>
                </DataTab>
            </DataTabs>
        </>
    );
};
