import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../config';
import { accessToken } from '../configuration/tokenHandling/accessToken';
import { AccessToken } from '../configuration/tokenHandling/tokenSlice';
import { ServiceCertificate, ServiceCertificateImpl } from '../features/app/context/certificates/certificates.types';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

// Define a service using a base URL and expected endpoints
export const serviceCertificatesStateApi = createApi({
    reducerPath: 'serviceCertificates',
    tagTypes: ['ServiceCertificatesState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_INIT_MONITOR_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchServiceCertificatesStates: builder.query<ServiceCertificate[], void>({
            query: () => ({
                url: '/service-certificates',
                responseHandler: async (response: Response) => {
                    if (response.status === 200) {
                        const data = await response.json();
                        // return data.map((item: any) => new ServiceCertificateImpl(item));
                        return data;
                    } else {
                        return [];
                    }
                },
            }),
            providesTags: ['ServiceCertificatesState'],
        }),
    }),
});

// Export hooks for usage in function components which are
export const {useFetchServiceCertificatesStatesQuery} = serviceCertificatesStateApi;
