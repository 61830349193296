import {FormattedMessage} from 'react-intl';
import React, {useState} from 'react';
import moment from 'moment';
import {Moment} from 'moment/moment';
import {DeviceList} from './components/DeviceList';
import {SIGNING_STATE} from './signing.types';
import {DEVICE_TYPE} from '../device-sidebar/device-state.types';
import {SigningSearch} from './components/SigningSearch';

export const SigningHome = () => {
    const [selectedSigningState, setSelectedSigningState] = useState<SIGNING_STATE>(SIGNING_STATE.REJECTED);
    const [selectedDeviceType, setSelectedDeviceType] = useState<DEVICE_TYPE>(DEVICE_TYPE.TBM3);
    const [startDateTime, setStartDateTime] = useState(moment().subtract(1, 'days').unix() * 1000);
    const [endDateTime, setEndDateTime] = useState(moment().unix() * 1000);
    const [hasSearchResults, setSearchResults] = useState(false);

    const onUpdateSelectedSigningState = (signingState: SIGNING_STATE) => {
        setSelectedSigningState(signingState);
    };

    const onUpdateSelectedDeviceType = (deviceType: DEVICE_TYPE) => {
        setSelectedDeviceType(deviceType);
    };

    const onTimeRangeChange = (start: Moment, end: Moment) => {
        setStartDateTime(start.unix() * 1000);
        setEndDateTime(end.unix() * 1000);
    };

    return (
        <>
            <h1 key="header"><FormattedMessage id={'intl-msg:coreconAdminWeb.deviceSigning.moduleName'}/></h1>
            <SigningSearch onResults={(status: boolean) => setSearchResults(status)}/>
            <DeviceList
                hide={hasSearchResults}
                key="systemStateDeviceList"
                signingFilter={{
                    signingState: selectedSigningState,
                    deviceType: selectedDeviceType,
                    startDateTime,
                    endDateTime
                }}
                onTimeRangeChange={onTimeRangeChange}
                onUpdateSelectedSigningState={onUpdateSelectedSigningState}
                onUpdateSelectedDeviceType={onUpdateSelectedDeviceType}
            />
        </>
    );
};
