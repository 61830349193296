import React from 'react';
import {PropertiesTable} from '../../../../../components/table/PropertiesTable';
import {deviceStateTBM3DetailsRowDescriptors} from '../data-descriptors/deviceStateDetailsRowDescriptors';
import {useAppSelector} from '../../../../../configuration/setup/hooks';
import {DEVICE_TYPE, DeviceStateMBB} from '../device-state.types';
import {DeviceStateDetailsMBB} from './DeviceStateDetailsMBB';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import {FormattedMessage} from 'react-intl';
import {getSelectedDeviceStateSidebar} from '../sidebarSlice';

const tableClassName = 'table table-condensed table-hover';

export const DevicePropertiesContainer = () => {
    const selectedDevice = useAppSelector(getSelectedDeviceStateSidebar);

    if (!selectedDevice) {
        return <FormattedMessage id="intl-msg:nothingFound"/>;
    }

    if (DEVICE_TYPE.OCU3 === selectedDevice.type) {
        return <>{
            selectedDevice?.vin ? <DeviceStateDetailsMBB selectedDevice={selectedDevice as DeviceStateMBB}/> :
                <ContentLoader/>
        }</>;
    } else {
        return <PropertiesTable showHeader={false} className={tableClassName} data={selectedDevice}
                                rowDescriptors={deviceStateTBM3DetailsRowDescriptors}/>;
    }
};
