import {useIntl} from 'react-intl';
import React from 'react';
import {ValueWithHint} from './components/ValueWithHint';
import {useFetchNasysResultStatesQuery} from '../../../../services/overviewStateApi';
import {BackendDevicesGraph} from './components/BackendDevices/Graph';
import {AmountBackendDevices} from './components/BackendDevices/AmountBackendDevices';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { getIdToken } from '../../../../configuration/tokenHandling/tokenSlice';
import { store } from '../../../../configuration/setup/store';
import AuthorisationWrapper from '../../../../components/authorisationWrapper';

export const OverviewHome = () => {
    const {formatMessage} = useIntl();
    const {data, isLoading} = useFetchNasysResultStatesQuery({}, {
        pollingInterval: 10000
    });

    let percentForSuccess = 0;
    let percentForNoSuccess = 0;
    let percentForReplaced = 0;
    let percentForLost = 0;
    if (data) {
        percentForSuccess = data.successDevices / data.total * 100;
        percentForNoSuccess = data.noSuccessDevices / data.total * 100;
        percentForReplaced = data.replacedDevices / data.total * 100;
        percentForLost = data.lostDevices / data.total * 100;
    }

    return (
        <>
            <h1>{formatMessage({id: 'intl-msg:coreconAdminWeb.overview.nasysResults'})}</h1>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ul className="display-flex justify-content-around list-inline list-unstyled">
                        <li className="col-sm-3">
                            <ValueWithHint isLoading={isLoading}
                                           value={data?.successDevices}
                                           valueColor="text-primary"
                                           unit={` (${percentForSuccess.toFixed(2)}%)`}
                                           unitColor="text-primary"
                                           hint={
                                               formatMessage(
                                                   {
                                                       // eslint-disable-next-line max-len
                                                       id: 'intl-msg:coreconAdminWeb.overview.nasysResults.successfullySigned'
                                                   }
                                               )
                                           }
                            />
                        </li>
                        <li className="col-sm-3">
                            <ValueWithHint isLoading={isLoading}
                                           value={data?.noSuccessDevices}
                                           valueColor="text-warning"
                                           unit={` (${percentForNoSuccess.toFixed(2)}%)`}
                                           unitColor="text-warning"
                                           hint={
                                               formatMessage(
                                                   {
                                                       // eslint-disable-next-line max-len
                                                       id: 'intl-msg:coreconAdminWeb.overview.nasysResults.notSigned'
                                                   }
                                               )
                                           }
                            />
                        </li>
                        <li className="col-sm-3">
                            <ValueWithHint isLoading={isLoading}
                                           value={data?.replacedDevices}
                                           valueColor="text-warning"
                                           unit={` (${percentForReplaced.toFixed(2)}%)`}
                                           unitColor="text-warning"
                                           hint={
                                               formatMessage(
                                                   {
                                                       // eslint-disable-next-line max-len
                                                       id: 'intl-msg:coreconAdminWeb.overview.nasysResults.replaced'
                                                   }
                                               )
                                           }
                            />
                        </li>
                        <li className="col-sm-3">
                            <ValueWithHint isLoading={isLoading}
                                           value={data?.lostDevices}
                                           valueColor="text-danger"
                                           unit={` (${percentForLost.toFixed(2)}%)`}
                                           unitColor="text-danger"
                                           hint={
                                               formatMessage(
                                                   {
                                                       // eslint-disable-next-line max-len
                                                       id: 'intl-msg:coreconAdminWeb.overview.nasysResults.lost'
                                                   }
                                               )
                                           }
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <hr/>
            <h1>{formatMessage({id: 'intl-msg:coreconAdminWeb.overview.registeredDevices'})}</h1>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ul className="display-flex justify-content-center list-inline list-unstyled">
                        <li className="col-sm-6 margin-right-20">
                            <label className='text-size-h5'>
                                {formatMessage({id: 'intl-msg:coreconAdminWeb.overview.registeredDevices.quarterly'})}
                            </label>
                            <div className="panel panel-default panel-body margin-bottom-0 height-300 width-500">
                                <BackendDevicesGraph/>
                            </div>
                        </li>
                        <li className="col-sm-6 margin-left-20">
                            <label className='text-size-h5'>
                                {formatMessage({id: 'intl-msg:coreconAdminWeb.overview.registeredDevices.select'})}
                            </label>
                            <div className="panel panel-default panel-body margin-bottom-0 height-300 width-500">
                                <AmountBackendDevices/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            {toDelete()}
        </>
    );
};

const toDelete = () => {
    return (
        <>
            <h1>Test Segment</h1>
            <div className="panel panel-default">
                <div className="panel-body">
                    <Button onClick={() => printAccessToken()}>
                        Test id tokens
                    </Button>
                    <AuthorisationWrapper allowedRoles={['rio.devicestate.support']}>
                        <Button className={'margin-left-10'}>This should be visible</Button>
                    </AuthorisationWrapper>
                    <AuthorisationWrapper allowedRoles={['this-role-does-not-exist']}>
                        <Button className={'margin-left-10'}>This should NOT be visible</Button>
                    </AuthorisationWrapper>
                </div>
            </div>
        </>
    );
};

const printAccessToken = () => {
    if (accessToken.hasAccessToken) {
        const token = accessToken.getAccessToken();
        console.warn(token);
    } else {
        console.warn('missing access token.');
    }
    const idToken = getIdToken(store.getState());
    console.warn(idToken!.roles);
};
