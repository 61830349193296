import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../configuration/setup/hooks';
import {
    useLazyFetchCertificateCM4Query,
    useLazyFetchCertificatesStatesCM4Query,
    useLazyFetchCertificatesStatesQuery,
    useLazyFetchCertificateTBMQuery,
} from '../../../../../services/deviceSigningStateApi';
import {DEVICE_TYPE} from '../device-state.types';
import {ColumnDescriptorProps, ListTable} from '../../../../../components/table/ListTable';
import {certificatesColumnDescriptors} from '../data-descriptors/certificatesColumnDescriptors';
import {
    CertificateInfoPayload,
    CertificatePayload,
    getSelectedCertificate,
    getSelectedCertificateSerialNumber,
    getSelectedDeviceCertificatesSidebar,
    getSelectedDeviceSidebar,
    saveCertificate,
    saveCertificateInfo,
    setSelectedCertificate
} from '../sidebarSlice';
import {union} from 'lodash';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import {deriveDeviceSidebarId} from '../SidebarRoutes';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/Popover';
import classNames from 'classnames';
import {DeviceSigningCertificateInfo} from '../device-signing.types';
import get from 'lodash/fp/get';

export const CertificatesContainer = () => {
    const dispatch = useAppDispatch();
    const selectedDeviceId: string = useAppSelector(getSelectedDeviceSidebar);
    const selectedDeviceCertificate = useAppSelector(getSelectedDeviceCertificatesSidebar);
    const selectedCertificate = useAppSelector(getSelectedCertificate);
    const selectedCertificateSerialNumber = useAppSelector(getSelectedCertificateSerialNumber);

    const [deviceType, deviceSerialNumber] = deriveDeviceSidebarId(selectedDeviceId);

    const [fetchCertificatesStates, {
        data: certificatesTMB3,
        isFetching: isFetchingTMB3
    }] = useLazyFetchCertificatesStatesQuery();

    const [fetchCertificatesCM4States, {
        data: certificatesCM4,
        isFetching: isFetchingCM4
    }] = useLazyFetchCertificatesStatesCM4Query();

    const [fetchCertificateTBM, {
        data: certificateTBM,
        isFetching: isFetchingCertificateTBM,
    }] = useLazyFetchCertificateTBMQuery();

    const [fetchCertificate, {
        data: certificateCm4,
        isFetching: isFetchingCertificateCM4,
    }] = useLazyFetchCertificateCM4Query();


    // If the selected device changes, fetch the certificates
    useEffect(() => {
        if (selectedDeviceCertificate === undefined && deviceType && deviceSerialNumber && !isFetching) {
            if ([DEVICE_TYPE.TBM3, DEVICE_TYPE.VCM].includes(deviceType)) {
                fetchCertificatesStates({serialNumber: deviceSerialNumber});
            } else if (DEVICE_TYPE.CM4 === deviceType) {
                fetchCertificatesCM4States({serialNumber: deviceSerialNumber});
            }
        }
    }, [selectedDeviceId]);

    // When certificates list change, dispatch action to save them
    useEffect(() => {
        dispatch(saveCertificateInfo({
            serialNumber: deviceSerialNumber,
            type: deviceType,
            certificateInfo: union(certificatesCM4, certificatesTMB3)
        } as CertificateInfoPayload));
    }, [certificatesTMB3, certificatesCM4]);

    // When a certificate is selected, fetch its details
    useEffect(() => {
        if (selectedCertificateSerialNumber !== '') {
            if (deviceType === DEVICE_TYPE.CM4) {
                fetchCertificate({certSerialNumber: selectedCertificateSerialNumber});
            } else if ([DEVICE_TYPE.TBM3, DEVICE_TYPE.VCM].includes(deviceType)) {
                fetchCertificateTBM({certSerialNumber: selectedCertificateSerialNumber});
            }
        }
    }, [selectedCertificateSerialNumber]);


    // When the certificate detail arrives, dispatch the save action
    useEffect(() => {
        if (certificateCm4) {
            dispatch(saveCertificate({
                certificate: certificateCm4,
                certSerialNumber: selectedCertificateSerialNumber
            } as CertificatePayload));
        } else if (certificateTBM) {
            dispatch(saveCertificate({
                certificate: certificateTBM,
                certSerialNumber: selectedCertificateSerialNumber
            } as CertificatePayload));
        }
    }, [certificateCm4, certificateTBM]);

    const isFetching = isFetchingTMB3 || isFetchingCM4;
    const isFetchingCertificate = isFetchingCertificateTBM || isFetchingCertificateCM4;

    const renderTableData = (item: DeviceSigningCertificateInfo,
                             columnDescriptor: ColumnDescriptorProps<DeviceSigningCertificateInfo>) => {
        if (columnDescriptor.hidden) {
            return;
        }
        return (
            <td key={`td-${columnDescriptor.id}`} className={classNames(columnDescriptor.className)}>
                {
                    columnDescriptor.format ?
                        columnDescriptor.format(get(columnDescriptor.field, item), item) :
                        get(columnDescriptor.field, item)
                }
            </td>
        );
    };

    const onOverlayTrigger = (show: boolean, item: DeviceSigningCertificateInfo) => {
        if (!show) {
            dispatch(setSelectedCertificate(''));
        } else {
            dispatch(setSelectedCertificate(item.certSerialNumber));
        }
    };

    const renderTableRow = (item: DeviceSigningCertificateInfo): undefined | JSX.Element => {
        if (!item) {
            return;
        }
        const tableData =
            certificatesColumnDescriptors.map(
                columnDescriptor => renderTableData(item, columnDescriptor), certificatesColumnDescriptors);
        const key = item.expire;
        const keyValue = key as unknown as string;
        return (
            <OverlayTrigger placement='auto' trigger={'click'}
                            onToggle={(show: boolean) => onOverlayTrigger(show, item)}
                            overlay={
                                <Popover contentClassName={'max-height-400 overflow-y-scroll'} id={'popover-positioned'}
                                         title={'Certificate'}
                                         placement={'auto'}>
                                    {isFetchingCertificate ? <Spinner text={'Loading'}/>
                                        :
                                        selectedCertificate !== undefined && selectedCertificate !== '' ?
                                            <div className={'white-space-render'}>
                                                {selectedCertificate}
                                            </div>
                                            :
                                            <div>{'No data available'}</div>
                                    }
                                </Popover>
                            }>
                <tr id={keyValue} key={keyValue} data-key={key}>
                    {tableData}
                </tr>
            </OverlayTrigger>
        );
    };


    return <>
        {!isFetching ?
            <ListTable className="table-bordered table-condensed height-100pct"
                       items={selectedDeviceCertificate !== undefined && selectedDeviceCertificate.length > 0 ?
                           selectedDeviceCertificate : []}
                       itemKey="expire"
                       columnDescriptors={certificatesColumnDescriptors}
                       isLoading={isFetching}
                       showHeader
                       renderTableRow={renderTableRow}
            />
            :
            <>
                <Spinner text={'Loading'}/>
            </>
        }
    </>;
};
