import React, {useEffect} from 'react';
import {DeviceStateContainer} from './containers/DeviceStateContainer';
import {useAppDispatch, useAppSelector} from '../../../../configuration/setup/hooks';
import {DefaultWhiteColumn} from '../../../../components/DefaultWhiteColumn';
import {DeviceSummary} from './DeviceSummary';
import {SidebarTabsComponent, TAB_NAME} from './SidebarTabs';
import {DevicePropertiesContainer} from './containers/DeviceStateDetailsContainer';
import {DEVICE_TYPE, DeviceStateMBB, DeviceStateTBM3} from './device-state.types';
import {SigningPropertiesContainer} from './containers/SigningPropertiesContainer';
import {CertificatesContainer} from './containers/CertificatesContainer';
import {
    getNoDataFetchedSidebar,
    getNotifiedForNoData,
    getSelectedDeviceSidebar,
    getSelectedDeviceSigningSidebar,
    getSelectedDeviceStateSidebar,
    getSelectedTab,
    saveDeviceState,
    setNoDataFetchedSidebar,
    setNotifiedForNoData,
    setSelectedDeviceSidebar,
    setSelectedTab
} from './sidebarSlice';
import {useLazyFetchDeviceTBM3StatesQuery} from '../../../../services/deviceStateApi';
import {DeviceListFilter, DeviceListPage} from '../device-state/components/DeviceList';
import Notification from '@rio-cloud/rio-uikit/Notification';
import {AllDeviceSigningInfo} from './device-signing.types';

export const deriveDeviceSidebarId = (id: string): [DEVICE_TYPE, string] => {
    const splitted = id.split('|');
    return [splitted[0] as DEVICE_TYPE, splitted[1]];
};

export const SidebarRoutes = () => {
    const dispatch = useAppDispatch();
    const selectedDeviceState: DeviceStateTBM3 | DeviceStateMBB | undefined =
        useAppSelector(getSelectedDeviceStateSidebar);
    const selectedDeviceSigning: AllDeviceSigningInfo | undefined =
        useAppSelector(getSelectedDeviceSigningSidebar);
    const selectedDeviceId: string = useAppSelector(getSelectedDeviceSidebar);
    const noDataFetched: boolean = useAppSelector(getNoDataFetchedSidebar);
    const notifiedForNoData: boolean = useAppSelector(getNotifiedForNoData);
    const selectedTab: TAB_NAME = useAppSelector(getSelectedTab);

    const [deviceType, deviceSerialNumber] = deriveDeviceSidebarId(selectedDeviceId);

    // Device Details
    const [fetchDeviceDetails, {
        data: deviceStateDetails,
        isFetching: isFetchingDeviceDetails,
    }] = useLazyFetchDeviceTBM3StatesQuery();

    useEffect(() => {
        if (deviceStateDetails === undefined || isFetchingDeviceDetails) {
            return;
        }
        const [derivedType, derivedSerialNumber] = deriveDeviceSidebarId(selectedDeviceId);
        const device = deviceStateDetails.items.find(item =>
            item.type === derivedType && item.serialNumber === derivedSerialNumber);
        if (device === undefined) {
            dispatch(setNoDataFetchedSidebar(true));
        } else {
            dispatch(setNoDataFetchedSidebar(false));
            dispatch(saveDeviceState(device));
            dispatch(setSelectedDeviceSidebar({device, context: undefined}));
        }
    }, [deviceStateDetails]);

    useEffect(() => {
        const derivedSerialNumber = selectedDeviceId.split('|')[1];
        if (derivedSerialNumber === selectedDeviceState?.serialNumber) {
            return;
        }
        fetchDeviceDetails({
            filterTBM3: {serialNumberPrefix: derivedSerialNumber} as DeviceListFilter,
            page: {limit: 100} as DeviceListPage
        });
    }, [selectedDeviceId]);

    const getSelectedTabContainer = () => {
        if (deviceType === DEVICE_TYPE.OCU3) {
            return <DevicePropertiesContainer/>;
        }
        switch (selectedTab) {
            case TAB_NAME.DETAILS:
                return <DevicePropertiesContainer/>;
            case TAB_NAME.SIGNING_DETAILS:
                return <SigningPropertiesContainer/>;
            case TAB_NAME.CERTIFICATES:
                return <CertificatesContainer/>;
            default:
                return <DevicePropertiesContainer/>;
        }
    };

    if (noDataFetched && selectedDeviceId !== '' && !notifiedForNoData) {
        dispatch(setNotifiedForNoData(true));
        const [derivedType, derivedSerialNumber] = deriveDeviceSidebarId(selectedDeviceId);
        Notification.warning(
            `Could not fetch device details of ${derivedType} ${derivedSerialNumber}`
        );
    }

    return (
        (!isFetchingDeviceDetails && deviceSerialNumber !== '') ?
            <div className="display-flex height-100pct">
                <DeviceStateContainer>
                    {<DefaultWhiteColumn className={'text-left'}>
                        <div className="position-sticky top-0 bg-white padding-bottom-5 z-index-3 non-printable">
                            <DeviceSummary
                                serialNumber={deviceSerialNumber}
                                type={deviceType}
                                device={selectedDeviceState}/>
                        </div>
                        <SidebarTabsComponent selectedTabId={selectedTab}
                                              setSelectedTabId={
                                                  (tabId: TAB_NAME) => dispatch(setSelectedTab(tabId))
                                              }
                                              deviceType={deviceType}/>
                        {
                            getSelectedTabContainer()
                        }
                    </DefaultWhiteColumn>
                    }
                </DeviceStateContainer>
            </div>
            : <div/>
    );
};
