import get from 'lodash/fp/get';

import classNames from 'classnames';

import React, {useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {TableHeader} from './TableHeader';
import {find} from 'lodash';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import {SortDirection} from '../utils/appsUtils';

// table-layout-fixed table-bordered
const tableClassName = 'table table-hover table-column-overflow-hidden table-head-filled table-sticky';

export const ACTIVE_CLASS = 'active';
export const DATA_ATTRIBUTE = 'data-key';

export interface ColumnDescriptorProps<T> {
    id: string;
    field: keyof T | string;
    label: string;
    className?: string;
    sortable?: boolean;
    sortingFn?: (a: T, b: T, sortDir: SortDirection) => number;
    format: (field: keyof T | string, item?: T, showExtended?: boolean) => JSX.Element[] | JSX.Element;
    formatLabel?: (field: string, item?: T) => object;
    hidden?: boolean;
    toHide?: (field: keyof T | string, item?: T, showExtended?: boolean) => boolean;
}

interface ListTableProps<T> {
    itemKey: keyof T;
    items: T[];
    className?: string;
    columnDescriptors: ColumnDescriptorProps<T>[];
    showHeader?: boolean;
    allowSelection?: boolean;
    allowSorting?: boolean;
    highlightSelectedRow?: boolean;
    preventRowClickEventPropagation?: boolean;
    searchValue?: string;
    isLoading?: boolean;
    onRowClick?: (rowId: string, isActiveRowId: boolean) => void;
    onRowClassName?: (item: T) => {};
    renderTableRow?: (item: T) => JSX.Element | undefined;
}

/**
 * Table component for lists
 */
export const ListTable = <T, >({
                                   itemKey = 'id' as keyof T,
                                   items,
                                   className,
                                   columnDescriptors,
                                   showHeader,
                                   allowSelection,
                                   allowSorting,
                                   highlightSelectedRow = false,
                                   preventRowClickEventPropagation = true,
                                   searchValue,
                                   isLoading = false,
                                   onRowClick,
                                   onRowClassName,
                                   renderTableRow,
                               }: ListTableProps<T>) => {
    const tableRef = useRef<HTMLTableElement>(null);
    const [activeRowId, setActiveRowId] = useState('');
    const [sortBy, setSortBy] = useState<keyof T>();
    const [sortDir, setSortDir] = useState(SortDirection.ASCENDING);

    const renderTableHead = () => {
        const selectionTableHeader = allowSelection ? <th/> : null;
        const tableHeaders = columnDescriptors.map((columnDescriptor: ColumnDescriptorProps<T>) => {
            if (columnDescriptor.hidden) {
                return;
            }
            return <TableHeader key={columnDescriptor.id} columnDescriptor={columnDescriptor} sortBy={sortBy}
                                sortDir={sortDir} handleSortChange={handleSortChange}
            />;
        }, columnDescriptors);
        return (
            <thead className="table-head">
            <tr key={'tableHeadRow'}>
                {selectionTableHeader}
                {tableHeaders}
            </tr>
            </thead>
        );
    };

    const handleDataSorting = () => {
        if (allowSorting) {
            const colDesc = columnDescriptors.find(columnDescriptor => columnDescriptor.id === sortBy);
            if (colDesc?.sortable) {
                if (!!colDesc.sortingFn) {
                    items = items.sort((a,b) => colDesc.sortingFn!(a, b, sortDir));
                } else {
                    items = items.sort();
                }
            }
        }
    };

    const renderTableBody = () => {
        let tableRows: (JSX.Element | undefined)[];
        handleDataSorting();
        if (renderTableRow) {
            tableRows = items.map(item => renderTableRow(item));
        } else {
            tableRows = items.map(item => defaultRenderTableRow(item));
        }
        return (
            <tbody>
            {tableRows}
            </tbody>
        );
    };

    const defaultRenderTableRow = (item: T): undefined | JSX.Element => {
        if (!item) {
            return;
        }
        const tableData =
            columnDescriptors.map(columnDescriptor => renderTableData(item, columnDescriptor), columnDescriptors);
        const key = item[itemKey];
        const keyValue = key as unknown as string;
        return (
            <tr className={classNames(onRowClassName && onRowClassName(item))}
                id={keyValue} key={keyValue} data-key={key}
                onClick={onRowClickDefault}>
                {tableData}
            </tr>
        );
    };

    const renderTableData = (item: T, columnDescriptor: ColumnDescriptorProps<T>) => {
        if (columnDescriptor.hidden) {
            return;
        }
        return (
            <td key={`td-${columnDescriptor.id}`} className={classNames(columnDescriptor.className)}>
                {
                    columnDescriptor.format ?
                        columnDescriptor.format(get(columnDescriptor.field, item), item) :
                        get(columnDescriptor.field, item)
                }
            </td>
        );
    };

    const onRowClickDefault = (event: React.MouseEvent<HTMLElement>) => {
        if (preventRowClickEventPropagation) {
            event.preventDefault();
            event.stopPropagation();
        }

        const rowId: string = event.currentTarget.getAttribute(DATA_ATTRIBUTE)!;
        setActiveRowId(activeRowId === rowId ? '' : rowId);


        const rows: HTMLCollectionOf<HTMLTableRowElement> | undefined = tableRef?.current?.rows;
        if (highlightSelectedRow) {
            highlightRow(rows, rowId);
            removeHighlightFromRow(rows, activeRowId);
        }
        return onRowClick && onRowClick(rowId, activeRowId !== rowId);
    };

    const getRowByDataAttribute = (rows: HTMLCollectionOf<HTMLTableRowElement> | undefined, value = '',
                                   attribute: string = DATA_ATTRIBUTE) =>
        find(rows, (row) => {
            const dataAttribute = row.attributes.getNamedItem(attribute);
            if (dataAttribute) {
                return dataAttribute.value === value;
            }
            return false;
        });

    const highlightRow = (rows: HTMLCollectionOf<HTMLTableRowElement> | undefined, rowId: string) => {
        const row = getRowByDataAttribute(rows, rowId);
        if (row) {
            row.classList.add(ACTIVE_CLASS);
        }
    };

    const removeHighlightFromRow = (rows: HTMLCollectionOf<HTMLTableRowElement> | undefined, rowId: string) => {
        const row = getRowByDataAttribute(rows, rowId);
        if (row) {
            row.classList.remove(ACTIVE_CLASS);
        }
    };

    const getSortDir = (previousSortBy: keyof T | undefined) => {
        if (sortBy === previousSortBy) {
            return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
        }
        return SortDirection.ASCENDING;
    };

    const handleSortChange = (event: React.MouseEvent<HTMLTableCellElement>) => {
        if (allowSorting) {
            const currentSortBy = sortBy;
            const newSortBy = event.currentTarget.getAttribute('data-sortby')!;
            setSortBy(newSortBy as keyof T);
            setSortDir(getSortDir(currentSortBy));
        }
    };

    if (!items) {
        return <FormattedMessage id="intl-msg:nothingFound"/>;
    }

    // Sort rows according to the sortBy and sortDir settings
    // const rows = sortBy ? sortByProperty(searchResult, sortBy, sortDir) : searchResult;

    const tableHead = renderTableHead();
    const tableBody = renderTableBody();
    return (
        <table ref={tableRef} className={classNames(tableClassName, className)}>
            {showHeader && tableHead}
            {isLoading ? <tbody>
                <tr>
                    <td colSpan={10}><ContentLoader/></td>
                </tr>
                <tr>
                    <td colSpan={10}><ContentLoader/></td>
                </tr>
                <tr>
                    <td colSpan={10}><ContentLoader/></td>
                </tr>
                </tbody>
                : tableBody
            }
        </table>
    );
};
