import React, {useEffect} from 'react';
import {PropertiesTable} from '../../../../../components/table/PropertiesTable';
import {useAppDispatch, useAppSelector} from '../../../../../configuration/setup/hooks';
import {detailsRowDescriptors, vcmDetailsRowDescriptors} from '../data-descriptors/signingDetailsRowDescriptors';
import {DEVICE_TYPE} from '../device-state.types';
import {AllDeviceSigningInfo, ApiDeviceSigningDataModelTBM3} from '../device-signing.types';
import {
    useLazyFetchDeviceSigningDetailsStatesCM4Query,
    useLazyFetchDeviceSigningDetailsStatesQuery,
    useLazyFetchDeviceSigningInitMonitorDetailsStatesQuery
} from '../../../../../services/deviceSigningStateApi';
import {
    getSelectedDeviceSidebar,
    getSelectedDeviceSigningSidebar,
    getSelectedDeviceSigningVCMSidebar,
    saveSigningInfo,
    saveVCMData
} from '../sidebarSlice';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import {deriveDeviceSidebarId} from '../SidebarRoutes';

const tableClassName = 'table table-condensed table-hover';

export const SigningPropertiesContainer = () => {
    const dispatch = useAppDispatch();
    const selectedDeviceId: string = useAppSelector(getSelectedDeviceSidebar);
    const selectedDeviceInDeviceSigning: AllDeviceSigningInfo | undefined
        = useAppSelector(getSelectedDeviceSigningSidebar);
    const vcmData: ApiDeviceSigningDataModelTBM3 | undefined = useAppSelector(getSelectedDeviceSigningVCMSidebar);

    const [deviceType, deviceSerialNumber] = deriveDeviceSidebarId(selectedDeviceId);

    const [fetchSigningState, {
        data: signingDetailsResult,
        isLoading: isFetchingSigningDetails
    }] = useLazyFetchDeviceSigningDetailsStatesQuery();

    const [fetchSigningStateCM4, {
        data: signingDetailsResultCM4,
        isLoading: isFetchingSigningDetailsCM4
    }] = useLazyFetchDeviceSigningDetailsStatesCM4Query();

    const [fetchMonitorDetails, {
        data: initMonitorDetails,
        isLoading: isFetchingDeviceInitMonitorDetails
    }] = useLazyFetchDeviceSigningInitMonitorDetailsStatesQuery();

    const isFetchingDetails = isFetchingSigningDetails || isFetchingSigningDetailsCM4;
    const isFetching = isFetchingDetails ||
        isFetchingDeviceInitMonitorDetails;

    const fetchData = () => {
        if (!isFetchingDetails) {
            if ([DEVICE_TYPE.TBM3, DEVICE_TYPE.VCM].includes(deviceType)) {
                fetchSigningState({
                    serialNumber: deviceSerialNumber,
                    identifier: deviceType === DEVICE_TYPE.VCM ? 'vcmserialnumber' : 'serialnumber'
                });
            } else if (deviceType === DEVICE_TYPE.CM4) {
                fetchSigningStateCM4({
                    serialNumber: deviceSerialNumber,
                    identifier: 'serialnumber'
                });
            }
        }
    };

    // Triggered on first render and selected device changes
    useEffect(() => {
        if (selectedDeviceInDeviceSigning === undefined ||
            selectedDeviceInDeviceSigning.signing?.serialNumber !== deviceSerialNumber ||
            selectedDeviceInDeviceSigning.signing?.deviceType !== deviceType) {
            fetchData();
        }
    }, [selectedDeviceId]);

    useEffect(() => {
        let results;
        let vcmResult;
        if (deviceType === DEVICE_TYPE.CM4) {
            results = signingDetailsResultCM4;
        } else {
            if (!!signingDetailsResult && signingDetailsResult.length > 0) {
                results = signingDetailsResult.find((result) => result.deviceType === deviceType);
                if (deviceType !== DEVICE_TYPE.VCM) {
                    vcmResult = signingDetailsResult.find((result) => result.deviceType === DEVICE_TYPE.VCM);
                    if (vcmResult !== undefined) {
                        dispatch(saveVCMData(vcmResult));
                    }
                }
            }
        }
        dispatch(saveSigningInfo({
            signing: results,
            monitor: initMonitorDetails
        } as AllDeviceSigningInfo));

    }, [signingDetailsResult, signingDetailsResultCM4, initMonitorDetails]);

    useEffect(() => {
        if (selectedDeviceInDeviceSigning?.monitor === undefined &&
            !isFetchingDeviceInitMonitorDetails &&
            selectedDeviceInDeviceSigning?.signing) {
            fetchMonitorDetails({imei: selectedDeviceInDeviceSigning?.signing?.imei});
        }
    }, [selectedDeviceInDeviceSigning]);

    return <>
        {!isFetching ?
            <div>
                <div>
                    <h6>{selectedDeviceInDeviceSigning?.signing?.deviceType}</h6>
                    <PropertiesTable
                        showHeader={false}
                        className={tableClassName}
                        data={selectedDeviceInDeviceSigning}
                        rowDescriptors={detailsRowDescriptors}
                    />
                </div>
                {
                    vcmData && selectedDeviceInDeviceSigning ?
                        <div>
                            <h6>VCM</h6>
                            <PropertiesTable
                                showHeader={false}
                                className={tableClassName}
                                data={vcmData}
                                rowDescriptors={vcmDetailsRowDescriptors}
                            />
                        </div>
                        : null
                }
            </div>
            :
            <>
                <Spinner text={'Loading'}/>
            </>
        }
    </>
        ;
};
