import React, { useEffect } from 'react';
import { ListTable } from '../../../../../components/table/ListTable';
import { columnDescriptors } from './columnDescriptors';
import { useFetchServiceCertificatesStatesQuery } from '../../../../../services/serviceCertificatesStateApi';
import { SERVICE_CERTIFICATE_TYPE, ServiceCertificate } from '../certificates.types';

interface CertificateListProps {
    tabKey: string;
}

export const CertificateList = ({tabKey}: CertificateListProps) => {
    const {data, isFetching, refetch} = useFetchServiceCertificatesStatesQuery();
    const isProdTab = tabKey === SERVICE_CERTIFICATE_TYPE.PRODUCTION;
    const filteredResult = data === undefined ? [] : data.filter(item => item.prod === isProdTab);
    const results
        = filteredResult.sort((a, b) => new Date(a.expirationDate!).getTime() - new Date(b.expirationDate!).getTime());

    useEffect(() => {
        refetch();
    }, [tabKey]);

    const rowStyle = (item: ServiceCertificate) => {
        const days = item.remainingDays!;
        return (days < 15) ? 'danger' : ((days < 30) ? 'warning' : '');
    };

    return (
        <ListTable itemKey={'host'}
                   items={results}
                   onRowClassName={(item) => rowStyle(item)}
                   isLoading={isFetching}
                   className={'table, table-bordered'}
                   columnDescriptors={columnDescriptors}
                   showHeader
        />
    );
};
